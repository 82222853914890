import React, { useState, useEffect } from "react";
import UserService from "../../services/userService";

const Education = () => {
  const [educationData, setEducationData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const fetchEducationData = async () => {
      try {
        const response = await UserService.getProfileDetail();
        const transformedEducation = response.education?.map((edu) => ({
          _id: edu._id, // Ensure unique keys for React
          school: edu.school || "Unknown Institution",
          degree: edu.degree?.category_name || "Unknown Degree",
          fieldOfStudy: edu.fieldOfStudy?.category_name || "Unknown Field of Study",
          startDate: edu.startDate,
          endDate: edu.endDate,
          isCurrentWork: !edu.endDate, // Assuming no endDate means it's ongoing
          description: edu.description || "No description available",
          media: edu.media || [], // Pass media objects for logo rendering
        }));
        setEducationData(transformedEducation || []);
      } catch (error) {
        console.error("Error fetching education data:", error);
      }
    };

    fetchEducationData();
  }, []);

  const displayedEducation = showMore ? educationData : educationData.slice(0, 2);

  const renderLogo = (logoUrl) => {
    if (logoUrl) {
      return (
        <img
          src={logoUrl}
          alt="Logo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
      );
    } else {
      return <p>No logo</p>;
    }
  };

  if (educationData.length === 0) {
    return null; // Don't render anything if there's no education data
  }

  return (
    <div>
      <div className="px-2 col pt-3">
        <div className="px-2 text-white w-100 h-100 d-flex align-items-end">
          <div
            className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
            style={{
              backgroundColor: "#151515",
              borderRadius: 10,
              gap: "10px",
            }}
          >
            <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
              Education
            </h2>

            {displayedEducation.map((education, index) => (
              <React.Fragment key={education._id}>
                <div
                  className="d-flex flex-row justify-content-start w-100 h-100 pb-2"
                  style={{
                    gap: "20px",
                  }}
                >
                  <div
                    className="d-flex pt-2 bg-black justify-content-center align-items-center"
                    style={{
                      width: 70,
                      height: 70,
                      borderRadius: 10,
                      border: "1px solid white",
                    }}
                  >
                    {renderLogo(education?.media?.[0]?.media_id?.media_url)}
                  </div>
                  <div
                    className="d-flex flex-column justify-content-start align-items-start"
                    style={{
                      width: "70%",
                    }}
                  >
                    <p
                      className="ct-text-size-14 mb-0"
                      style={{
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      {education?.school}
                    </p>
                    <p
                      className="ct-text-size-14 mb-0"
                      style={{
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      {education?.degree}
                    </p>
                    <p
                      className="ct-text-size-14 mb-0"
                      style={{
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      {`${new Date(education?.startDate).toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          year: "numeric",
                        }
                      )} - ${
                        education.isCurrentWork
                          ? "Present"
                          : new Date(education?.endDate).toLocaleDateString(
                              "en-US",
                              {
                                month: "short",
                                year: "numeric",
                              }
                            )
                      }`}
                    </p>
                    <p
                      className="ct-text-size-14 mb-0"
                      style={{
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      {education?.fieldOfStudy}
                    </p>
                    <p
                      className="ct-text-size-14 mb-0"
                      style={{
                        color: "grey",
                        textAlign: "left",
                      }}
                    >
                      {education?.description}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ))}

            {educationData.length > 2 && (
              <div className="mt-2 ct-text-size-14 d-flex justify-content-center align-items-center w-100">
                <button
                  onClick={() => setShowMore(!showMore)}
                  style={{
                    backgroundColor: "transparent",
                    color: "#89CFF0",
                    border: "none",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {showMore ? "Show less" : "Show more"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
