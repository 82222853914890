import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonial1 from "../../assets/testimonial/avatar_test_2.png";
import testimonial2 from "../../assets/testimonial/avatar_test_1.png";
import testimonial4 from "../../assets/testimonial/avatar_test_5.png";
import testimonial5 from "../../assets/testimonial/avatar_test_4.png";
import testimonial6 from "../../assets/testimonial/avatar_test_3.png";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

const testimonials = [
  {
    id: 1,
    videoURL: "https://youtu.be/V_KfkhiJ_H8?si=4b0ajkxqsS6YFtk1",
    avatar: testimonial1,
    name: "Prasanna",
    quote:
      '"First of a kind award for a cover song. Very big recognition for my team and myself. A lot of people will get on stage if this recognition continues. Thank you Casttree team for its enormous support"',
    role: "Winner of Casttree Award 2019",
    award: "Best Cover Song category",
    cardHeight: "230px",
  },
  {
    id: 2,
    videoURL: "https://youtu.be/y8jCrRwqE94?si=5jipO4b2tKTE0DKe",
    avatar: testimonial2,
    name: "Ragunanthan",
    quote:
      '"Thanks to Casttree and we are very glad that we applied for this. Got to know about Casttree through a friend. It is more than a film festival. I am sure that we will be able to find more talents through Casttree to collaborate and make better projects"',
    role: "Winner of Casttree Award 2018",
    award: "Shortfilm category",
    cardHeight: "230px",
  },
  {
    id: 3,
    videoURL: "https://youtu.be/J9tswFBFgMU?si=QmN2J5SQ0KIv9zke",
    name: "Director K. Bhagyaraj",
    quote: "",
    role: "Chief Guest of Casttree Award 2018",
    award:
      "Addressing the gathering at Casttree Awards 2018 held at SRM University, Chennai",
    cardHeight: "230px",
  },
  {
    id: 4,
    videoURL: "https://www.youtube.com/watch?v=yBzyLn1bm8E&t=157s",
    avatar: testimonial4,
    name: "VJ Vijay",
    quote:
      '"A recognition for an independent artist is something that I have unheard of. I take pride in saying that a lot of independent artists and short film directors have received recognition here at Casttree Awards"',
    role: "Winner of Casttree Award 2019",
    award: "Best Lyricist",
    cardHeight: "230px",
  },
  {
    id: 5,
    videoURL: "https://www.youtube.com/watch?v=_V1fMUHCxyw&t=134s",
    avatar: testimonial5,
    name: "Deepthi",
    quote:
      '"I would like to thank Casttree for arranging an amazing event for people like us to get recognized"',
    role: "Winner of Casttree Award 2018",
    award: "Best Performance Female",
    cardHeight: "230px",
  },
  {
    id: 6,
    videoURL: "https://www.youtube.com/watch?v=U7zQOXrzXpk&t=134s",
    avatar: testimonial6,
    name: "Bharath Krishna",
    quote:
      '"Thank you Casttree for creating an atmosphere where we can get together and network. I wholeheartedly recommend aspiring Casttree to aspiring talents seeking recognition in the music industry"',
    role: "Winner of Casttree Award 2019",
    award: "Best Musician category",
    cardHeight: "230px",
  },
];

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-arrow-prev" onClick={onClick}>
      <KeyboardArrowLeftRoundedIcon />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-arrow-next" onClick={onClick}>
      <KeyboardArrowRightRoundedIcon />
    </div>
  );
};

const Testmonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "30px",
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };

  const extractVideoID = (url) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v") || urlObj.pathname.split("/").pop();
  };

  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="container mt-5 landing-page-main"
      id="aboutUs"
    >
      <div className="ct-top-30">
        <div className="head">
          <div className="head-div mx-auto ct-center-fixed">
            <Fade direction="left" cascade>
              <section className="div-1 home-text-center">
                <h1 className="ct-title">STARS FROM CASTTREE</h1>
                <p className="ct-para-text">
                  Don’t take what we say! Hear from the stars themselves{" "}
                </p>
              </section>
            </Fade>
          </div>

          <div className="slider-container testimonial-data">
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div className="video-card ct-pointer" key={testimonial.id}>
                  <div className="testimonial-video-card">
                    <iframe
                      width="100%"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractVideoID(
                        testimonial.videoURL
                      )}?rel=0`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                    <div className="testimonial-details pt-3 testimonal-px cardHeight">
                      <div className="testimonial-avatar-flex">
                        {testimonial.avatar && (
                          <div className="">
                            <img
                              src={testimonial.avatar}
                              alt={testimonial.name}
                              className="testimonial-avatar"
                            />
                          </div>
                        )}
                        <div
                          className="text-left"
                          style={{ paddingLeft: 10, textAlign: "left" }}
                        >
                          <p className="testimonial-quote testimonal-px">
                            {testimonial.quote}
                          </p>
                          <div className="testimonial-details pt-3 testimonal-px">
                            <p className="panel-title mb-1">
                              {testimonial.name}
                            </p>
                            <p className="panel-sub-title mb-1">
                              {testimonial.role}
                            </p>
                            <p className="panel-sub-title mb-0">
                              {testimonial.award}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testmonial;
