import React from "react";
import { Fade } from "react-awesome-reveal";
import Jury1 from "../../assets/jury1.png";
import Jury2 from "../../assets/jury2.png";
import Jury3 from "../../assets/jury3.png";
import Jury4 from "../../assets/jury4.png";
import Jury5 from "../../assets/jury5.png";
import Jury6 from "../../assets/jury6.png";
import Jury7 from "../../assets/jury7.png";
import Jury8 from "../../assets/jury8.png";
import JuryLeftToRight from "./juryLeftToRight";
import JuryRightToLeft from "./juryRightToLeft";

const images = [
  { image: Jury2 },
  { image: Jury3 },
  { image: Jury4 },
  { image: Jury7 },
];
const imagesRL = [
  { image: Jury6 },
  { image: Jury1 },
  { image: Jury5 },
  { image: Jury8 },
];

const JurySection = () => {
  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="container mt-5 landing-page-main"
      id="Jury"
    >
      <div>
        <Fade direction="left" cascade>
          <div className="head mt-5 pt-5">
            <div className="head-div mx-auto ct-center-fixed">
              <section className="div-1 home-text-center">
                <h1 className="ct-title">OUR JURIES</h1>
                <p className="ct-para-text">
                  Your work is going to be reviewed by the best minds in the
                  industry.
                </p>
              </section>
            </div>
            <div className="mt-4">
              <JuryLeftToRight images={images} scrollSpeed={30} />
            </div>
            <div className="mt-4">
              <JuryRightToLeft images={imagesRL} scrollSpeed={30} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default JurySection;
