import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import logoImage from "../../assets/ct_trans.png";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";

const NavbarEmptyComponent = () => {
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header-nav");
      if (window.scrollY > 200) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    authService.doLogout();
  };

  return (
    <>
      <Navbar
        className="header-nav navbar-dark workshop-landing-main"
        fixed="top"
        style={{ color: "#000", padding: 10, backgroundColor: "#FFF" }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5"
            style={{ paddingLeft: 30, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </Navbar.Brand>
        </Fade>
      </Navbar>
    </>
  );
};

export default NavbarEmptyComponent;
