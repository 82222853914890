import React from "react";
import { useParams } from "react-router-dom";
import star from "../../assets/Star.png";

const WorkshopMentor = ({ workshopDetail }) => {
  const { workShopId } = useParams();

  return (
    <div className="text-white p-lg-5 p-2">
      <div
        className="d-flex col mt-lg-4 mt-5"
        style={{ alignItems: "center", justifyContent: "center", gap: 10 }}
      >
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
        <p
          className="ct-text-size-24 text-white  mb-0"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Meet your mentor
        </p>
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
      </div>

      <div className="row mt-lg-5 mt-4  ms-lg-5 ms-3 align-items-start justify-content-lg-center">
        <div className="col-lg-3 col-md-4 col-10 d-flex flex-column align-items-center">
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <img
                src={workshopDetail?.profileData?.media}
                alt="Mentor"
                className="ct-Workshop_mentor_img"
              />
            </div>
          </div>

          {/* Mobile and tablet view for Mentor details */}
          <div className="ct-Workshop_mentor col-12 d-flex flex-column align-items-start d-md-none ms-5">
            <div>
              <p className="mb-1 ct-workshop-semi-16 mt-2">
                {workshopDetail?.profileData?.displayName?.toUpperCase()}
              </p>
              <p className="mb-2 ct-text-size-16-med">
                {workshopDetail?.profileData?.about}
              </p>
              <div
                className="col-12 d-flex align-items-center mb-4 mt-3"
                style={{ gap: "10px" }}
              >
                {workshopDetail?.profileData?.socialMedia?.map(
                  (social, index) => (
                    <a
                      key={index}
                      href={social?.socialMediaLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={social?.icon}
                        alt={social?.socialMediaType}
                        className="Mentor_Links"
                      />
                    </a>
                  )
                )}
              </div>
              <p className="mb-0">{workshopDetail?.profileData?.about2}</p>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-8 col-12">
          {/* Tablet and desktop view for Mentor details */}
          <div className="d-none d-md-block">
            <div className="col-12">
              <p className="mb-0 ct-Text-semi-32">
                {workshopDetail?.profileData?.displayName?.toUpperCase()}
              </p>
              <p className="mb-0">{workshopDetail?.profileData?.about}</p>
              <div
                className="col-12 d-flex align-items-center mb-4 mt-2"
                style={{ gap: "10px" }}
              >
                {workshopDetail?.profileData?.socialMedia?.map(
                  (social, index) => (
                    <a
                      key={index}
                      href={social?.socialMediaLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={social?.icon}
                        alt={social?.socialMediaType}
                        className="Mentor_Links"
                      />
                    </a>
                  )
                )}
              </div>
              <p className="mb-0">{workshopDetail?.profileData?.about2}</p>
            </div>
          </div>

          <div className="mt-4 mt-lg-0">
            {workshopDetail?.profileData?.experience?.[0]?.roleDescription && (
              <div>
                <p className="mb-0 ct-text-size-20-Workshop">Experience:</p>
                <p className="ct-Mentor-regular-20">
                  {
                    workshopDetail?.profileData?.experience?.[0]
                      ?.roleDescription
                  }
                </p>
              </div>
            )}
            {workshopDetail?.profileData?.education?.[0]?.courseName && (
              <div>
                <p className="mb-0 ct-text-size-20-Workshop">Education:</p>
                <p className="ct-Mentor-regular-20">
                  {workshopDetail?.profileData?.education?.[0]?.courseName}
                </p>
              </div>
            )}
            {(workshopDetail?.profileData?.roles?.[0]?.role_name ||
              workshopDetail?.profileData?.roles?.[0]?.institution) && (
              <div>
                <p className="mb-0 ct-text-size-20-Workshop">Current Role:</p>
                <p className="ct-Mentor-regular-20">
                  {workshopDetail?.profileData?.roles?.[0]?.role_name ||
                    "Role not available"}
                  ,{" "}
                  {workshopDetail?.profileData?.roles?.[0]?.institution ||
                    "Institution not available"}
                </p>
              </div>
            )}
            {workshopDetail?.profileData?.expertise && (
              <div>
                <p className="mb-0 ct-text-size-20-Workshop">Expertise:</p>
                <p className="ct-Mentor-regular-20">
                  {workshopDetail?.profileData?.expertise}
                </p>
              </div>
            )}
            {workshopDetail?.profileData?.approach && (
              <div>
                <p className="mb-0 ct-text-size-20-Workshop">Approach:</p>
                <p className="ct-Mentor-regular-20">
                  {workshopDetail?.profileData?.approach}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopMentor;