import React, { useState } from "react";
import logoImage from "../assets/casttree_logo.png";
import overley from "../assets/overley_background.png";
import { useNavigate } from "react-router-dom";
import Footer from "./events/footer";
import { Fade } from "react-awesome-reveal";
import SEO from "./common/seo";
import CommunityBanner from "./events/communityBanner";
import playstore from "../../src/assets/playstore.png";
import appstore from "../../src/assets/app_store.png";

const Home = () => {
  const navigate = useNavigate();
  const navigateToWorkshops = () => {
    navigate("/workshops");
  };
  const navigateToEvents = () => {
    navigate("/event");
  };
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="landing-page-main" onClick={closeModal}>
      <SEO
        title="Casttree : Turn your passion into profession"
        description="Casttree is a platform where talents come together to make their passion into a profession."
      />

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <Fade direction="down" cascade>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <div className="modal-content">
                <img src={overley} alt="QR Code" width="500" />
                <span
                  className="ct-text-24"
                  style={{
                    color: "white",
                    position: "absolute",
                    top: 30,
                    paddingLeft: 450,
                  }}
                  onClick={closeModal}
                >
                  &#10005;
                </span>
              </div>
            </div>
          </Fade>
        </div>
      )}
      {isModalOpen && (
        <div className="modal-overlay-Phone">
          <Fade direction="up" cascade>
            <div className="fade-up">
              <div
                style={{
                  position: "fixed",
                  left: 0,
                  top: 0,
                  height: "100vh",
                }}
                className="d-flex justify-center align-items-end w-100"
              >
                <div
                  style={{
                    background: "linear-gradient(to right, #B900CA, #FF774B)",
                    height: 400,
                  }}
                  className="d-flex justify-center align-items-flex-start w-100"
                >
                  <div
                    style={{
                      padding: 20,
                    }}
                    className="d-flex justify-center align-items-center flex-column"
                  >
                    <h3 className="text-white ct-text-size-50 ">Casttree</h3>
                    <p className="text-white text-center mt-2 ct-Text-semi-24 ">
                      Helping creative talents craft their passion into a
                      lasting profession
                    </p>
                    <div className="flex justify-center mt-6 pt-4">
                      <a href="https://onelink.to/nje5yj" target="_blank">
                        <button
                          className="bg-white text-grey  py-3 ct-text-size-22   "
                          style={{
                            borderRadius: 10,
                            width: 250,
                            border: "none",
                            color: "#3B3B3B",
                          }}
                        >
                          Download now
                        </button>
                      </a>
                    </div>
                    <div className="flex justify-center mt-6 pt-4">
                      <p className="text-white text-center mt-2 ct-Text-semi-24 ">
                        Available in both
                      </p>

                      <div className="mbl-text-center">
                        <a href="https://fv4b.short.gy/PKKWyx" target="_blank">
                          <img
                            src={playstore}
                            width={100}
                            style={{ marginRight: 30 }}
                          />
                        </a>
                        <a href="https://fv4b.short.gy/YCG6ws" target="_blank">
                          <img src={appstore} width={100} />
                        </a>
                      </div>
                    </div>
                  </div>

                  <span
                    className="ct-text-24"
                    style={{
                      color: "white",
                      position: "relative",
                      right: 10,
                      top: 10,
                    }}
                    onClick={closeModal}
                  >
                    &#10005;
                  </span>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      )}

      <div className="ct-p-5 ct-d-flex-justify-center-home">
        <Fade direction="left" cascade>
          <div className="">
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </div>
        </Fade>
        <Fade direction="right" cascade>
          <div
            className="ct-pointer"
            style={{
              display: "flex",
              gap: 30,
              color: "rgba(255, 255, 255, 1)",
            }}
          >
            <p>About</p>
            <p onClick={navigateToEvents}>Events</p>
            <p onClick={navigateToWorkshops}>Workshops</p>
          </div>
        </Fade>
      </div>
      <div className="head landing-text-top head-div mx-auto">
        <Fade direction="down" cascade>
          <div className="" style={{ textAlign: "center" }}>
            <h1
              className="ct-home-title-100"
              style={{ color: "#FFF", paddingBottom: 20 }}
            >
              fueling dreams, <br />
              shaping futures
            </h1>
            <p className="ct-home-para">
              Casttree is a platform where talents come together to make their
              passion into a profession.
            </p>
          </div>
          <div
            style={{
              paddingTop: 60,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <a href="https://onelink.to/nje5yj" target="_blank">
              <button
                className="ct-text-size-24"
                style={{
                  marginTop: 20,
                  padding: "10px 20px",
                  width: 300,
                  borderRadius: 15,
                  color: "white",
                  background:
                    "linear-gradient(to right, #B900CA,#F75EB0, #FF774B)",
                  border: "none",
                }}
              >
                Download The App
              </button>
            </a>
          </div>
        </Fade>
      </div>

      <div className="" style={{ paddingTop: 60 }}>
        <CommunityBanner />
      </div>
      <div className="" style={{ paddingTop: 45 }}>
        <Footer showSocialMediaLinks={true} />
      </div>
    </div>
  );
};

export default Home;
