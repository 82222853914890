import React, { useEffect, useState } from "react";
import silverIcon from "../../assets/silver.png";
import goldIcon from "../../assets/gold.png";

const SkillBadge = ({ skill }) => (
  <div
    className="px-3 pt-2 d-flex justify-content-center align-items-end"
    style={{
      width: "auto",
      height: 45,
      backgroundColor: "#FFE9A7",
      borderRadius: 10,
      border: "none",
    }}
  >
    <p
      className="ct-text-size-16"
      style={{
        height: 20,
        color: "#814416",
        textAlign: "center",
      }}
    >
      {skill}
    </p>
  </div>
);

const LanguageBadge = ({ language }) => (
  <div className="col" style={{ gap: 10 }}>
    <div
      className="px-4 pt-2 d-flex justify-content-center align-items-center"
      style={{
        height: 50,
        width: 50,
        border: "none",
        borderRadius: 50,
        backgroundColor: "#000",
        color: "#FFF",
        fontSize: "18px",
        textAlign: "center",
        bottom: 10,
      }}
    >
      {language?.media?.[0]?.mediaId?.location ? (
        <img
          src={language.media[0].mediaId.location}
          alt={language.language_name}
          className="ct-text-size-16"
          style={{
            height: 40,
            width: 40,
          }}
        />
      ) : (
        language.language_name?.charAt(0).toUpperCase()
      )}
    </div>
    <p className="ct-text-size-16 mt-1">{language.language_name}</p>
  </div>
);

const ProfileHeader = ({ profileDetailData }) => {
  const [rolesValue, setRolesValue] = useState("");

  useEffect(() => {
    if (profileDetailData?.roles) {
      const roleNames = profileDetailData?.roles
        .map((role) => role?.category_name)
        .join(" | ");
      setRolesValue(roleNames);
    }
  }, [profileDetailData]);

  const skills =
    profileDetailData?.skills?.map((skill) => skill.skill_name) || [];
  const languages =
    profileDetailData?.language?.map((lang) => lang.languageId) || [];
  const about = profileDetailData?.about;

  return (
    <div className="d-flex text-white text-center flex-column">
      <div className="text-center w-100 h-100 bg-black" style={{ gap: 40 }}>
        <div
          style={{
            height: 240,
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
            url(${profileDetailData?.coverImage?.[0]?.media_id?.location})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",

          }}
        >
                <div className="d-flex justify-content-center align-items-end"
                style={{height:280,}}>
          <img
            src={profileDetailData?.media?.[0]?.media_id?.location}
            alt="profilepic"
            className="img-fluid bg-black"
            style={{
              borderRadius: 100,
              width: 150,
              height: 150,
              justifyContent:"flex-end",
              border: "2px solid white",
              objectFit: "cover",
            }}
          />
        </div>

        </div>
  
        <div className="pt-5">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: 10 }}
          >
            <p className="ct-cirka-size-22 mb-0">
              {profileDetailData?.userId?.userName}
            </p>
          </div>
          <p className="ct-text-size-14-grey mb-0">
            @{profileDetailData?.userName}
          </p>
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <p className="ct-text-size-14-grey mb-0">
              {rolesValue}
            </p>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center mt-0">
            <p className="ct-text-size-14-grey ">
              {profileDetailData?.userId?.city?.city_name}
              <span style={{ margin: "0 5px" }}>|</span>
              {profileDetailData?.userId?.state?.state_name}
            </p>
          </div>
        </div>
      </div>
      <div className="p-2 col" style={{ height: "100%" }}>
        {/* About Section */}
        {about && about.trim() && (
          <div className="p-2 text-white w-100 h-100 d-flex align-items-center">
            <div
              className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
              style={{
                backgroundColor: "#151515",
                borderRadius: 10,
                gap: "10px",
                textAlign: "left",
              }}
            >
              <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
                About
              </h2>
              <p className="ct-text-size-14-grey">
                {about || "No details available."}
              </p>
            </div>
          </div>
        )}

        {/* Skills Section */}
        {skills.length > 0 && (
          <div className="p-2 text-white w-100 h-100 d-flex align-items-center">
            <div
              className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
              style={{
                backgroundColor: "#151515",
                borderRadius: 10,
                gap: "10px",
              }}
            >
              <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
                Skills
              </h2>
              <div className="d-flex row p-2" style={{ gap: 10 }}>
                {skills.map((skill, index) => (
                  <SkillBadge skill={skill} key={index} />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Languages Section */}
        {languages.length > 0 && (
          <div className="p-2 text-white w-100 h-100 d-flex align-items-center">
            <div
              className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
              style={{
                backgroundColor: "#151515",
                borderRadius: 10,
                gap: "10px",
              }}
            >
              <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
                Languages
              </h2>
              <div className="row">
                {languages.map((lang, index) => (
                  <LanguageBadge language={lang} key={index} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
