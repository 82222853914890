import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ComputerIcon from "@mui/icons-material/Computer";
import TranslateIcon from "@mui/icons-material/Translate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Button } from "react-bootstrap";
import workshopService from "../../services/workshopServices";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/c_logo.png";
import authService from "../../services/authService";
import WhatsAppWorkShop from "../../assets/whatsapp_WorkShop.png";

const WorkshopBannerCard = ({ workshopDetail }) => {
  const { workShopId } = useParams();
  const [storeAccessToken, setStoreAccessToken] = useState("");
  const [communityURL, setCommunityURL] = useState(null);
  const [workshopData, setWorkshopData] = useState({
    cardData: [],
    participantsCount: 0,
  });
  const [targetEndTime, setTargetEndTime] = useState(null);
  const navigate = useNavigate();
  const [workshopDetailData, setWorkshopDetailData] = useState(null);
  const [workshopParticipantData, setWorkshopParticipantData] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    setStoreAccessToken(accessToken);
    if (workshopDetail) {
      console.log("Workshop Detail:", workshopDetail);
      updateWorkshopData(workshopDetail);
      setWorkshopDetailData(workshopDetail)
      setTargetEndTime(
        new Date(workshopDetail?.itemId?.additionalDetail?.registrationExpiry)
      );
    } else if (workshopDetail && accessToken) {
      workshopParticipants(workshopDetail);
    }
  }, [workshopDetail]);

  const workshopParticipants = async (workshopDetailData) => {
    console.log("Workshop Id:", workShopId);
    try {
      const response = await workshopService.getPremiumDetail(
        workshopDetailData?.itemId?._id
      );
      setWorkshopParticipantData(response);
      if (storeAccessToken && response?.data?.isApplied) {
        navigate(`/workshop/success/${workShopId}`);
      } else {
        navigate(`/workshop/login/${workShopId}`);
      }
      console.log("Workshop Participant Data:", response);
    } catch (error) {
      console.log("Error fetching workshop participant data:", error);
    }
  };

  const updateWorkshopData = (response) => {
    if (response?.itemId?.additionalDetail) {
      setWorkshopData({
        cardData: [
          {
            icon: <CalendarTodayIcon />,
            title: "Date",
            description: `${new Date(
              response?.itemId?.additionalDetail?.workShopStartDate
            )
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
              .replace(/ /g, " ")}`,
          },
          {
            icon: <AccessTimeIcon />,
            title: "Time",
            description:
              response?.itemId?.additionalDetail?.startTime +
              " - " +
              response?.itemId?.additionalDetail?.endTime,
          },
          {
            icon: <ComputerIcon />,
            title: "Mode",
            description: response?.itemId?.additionalDetail?.mode,
          },
          {
            icon: <TranslateIcon />,
            title: "Language",
            description: response?.language?.[0]?.languageName,
          },
        ],
        participantsCount: response?.participantsCount || 0,
      });
    }
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (targetEndTime) {
        const now = new Date();
        const difference = targetEndTime - now;

        if (difference <= 0) {
          clearInterval(timer);
          setTimeLeft({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          });
        } else {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setTimeLeft({
            days,
            hours,
            minutes,
            seconds,
          });
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetEndTime]);

  const goToWorkshoplogin = () => {
    if (storeAccessToken) {
      workshopParticipants(workshopDetailData);
    }
    else {
      navigate(`/workshop/login/${workShopId}`);
    }
  };

  useEffect(() => {
    const fetchCommunityJoin = async () => {
      try {
        const response = await authService.getCommunityJoinChennal();
        setCommunityURL(response);
      } catch (error) {
        console.error("Error fetching community:", error);
      }
    };

    fetchCommunityJoin();
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${workshopDetail?.itemId?.additionalDetail?.bannerImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="p-3 p-lg-5 ">
          <div className="text-center px-1 px-lg-1">
            <p
              style={{ lineHeight: 1.1 }}
              className="ct-text-size-70 Ws-banner-fill-small "
            >
              {workshopDetail?.itemId?.itemName?.toUpperCase()}
            </p>
          </div>
          <div className="text-center pb-4">
            <p style={{ color: "white" }} className="ct-banner-22 ">
              {workshopDetail?.itemId?.itemDescription}
            </p>
          </div>
          <div className="ct-phone-block">
            <div className="row px-lg-2 d-flex justify-content-end ">
              {workshopData.cardData.map((card, index) => (
                <div className="col-12 col-md-6 col-lg-3 mb-3" key={index}>
                  <div
                    style={{
                      backgroundColor: "#1C1C1E",
                      gap: 20,
                    }}
                    className="card d-flex flex-row align-items-center p-2 "
                  >
                    <div
                      className="p-2"
                      style={{ backgroundColor: "#93939340", borderRadius: 2 }}
                    >
                      {card.icon}
                    </div>
                    <div>
                      <p
                        style={{ color: "white" }}
                        className="mb-0 ct-about-para-text "
                      >
                        {card.title}
                      </p>
                      <p
                        style={{ color: "white" }}
                        className="mb-0 ct-Banner-mode"
                      >
                        {card.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-4 ct-phone-block">
            <p className=" text-white ct-banner-Text mb-0">
              Grab one-time deal at{" "}
            </p>
          </div>
          <div
            className="d-lg-flex justify-content-center align-items-center ct-phone-block "
            style={{ gap: 10 }}
          >
            <p className="Ws-banner-fill-small ct-Banner-price">
              @{workshopDetail?.itemId?.currency?.currency_code}{" "}
              {workshopDetail?.itemId?.price}
            </p>
            <p
              className="ct-compare-price text-white"
              style={{ textDecoration: "line-through" }}
            >
              {workshopDetail?.itemId?.currency?.currency_code}{" "}
              {workshopDetail?.itemId?.comparePrice}
            </p>
          </div>
        </div>
      </div>
      <div
        className="container-fluid-work rounded ct-phone-block aglin-items-end"
        style={{
          position: "fixed",
          bottom: "0",
          left: "0",
          boxShadow: "#4e4e4e 0px -4px 8px",
          width: "100%",
          padding: "5px 15px",
          zIndex: 1000,
        }}
      >
        <div className="row bg-sm-black ">
          <div className="col-md-7 align-items-center">
            <p className=" ct-banner-Text mb-0">Registration ends in</p>
            <div className="d-flex">
              <div className="d-flex align-items-end">
                <p className="mb-0 ct-banner-Text-28">{timeLeft.days}</p>
                <span className="ms-2 mb-1 ct-banner-Text">Days</span>
              </div>
              <div className="ms-3 d-flex align-items-end">
                <p className="mb-0 ct-banner-Text-28">{timeLeft.hours}</p>
                <span className="ms-2 mb-1 ct-banner-Text">Hours</span>
              </div>
              <div className="ms-3 d-flex align-items-end">
                <p className="mb-0 ct-banner-Text-28">{timeLeft.minutes}</p>
                <span className="ms-2 mb-1 ct-banner-Text">Minutes</span>
              </div>
              <div className="ms-3 text-center d-flex align-items-end">
                <p className="mb-0  ct-banner-Text-28">{timeLeft.seconds}</p>
                <span className="ms-2t-banner-Text ms-2 mb-1">Seconds</span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 d-flex align-items-center justify-content-lg-end ">
            <div className="align-items-lg-center d-flex mt-3 me-3 ">
              <p className=" ct-Banner-part ">
                {workshopDetail?.itemId?.additionalDetail?.ratingSummary}
              </p>
            </div>
            {targetEndTime && new Date() > targetEndTime ? (
              <p
                className="text-white px-5 py-3 Banner-timer-button"
                style={{ backgroundColor: "grey", cursor: "not-allowed" }}
              >
                Registration Closed
              </p>
            ) : (
              <Button
                variant="gradient"
                className="next-btn-small-dark px-sm-0 py-sm-0 text-white px-lg-5 py-lg-2 Banner-timer-button"
                onClick={goToWorkshoplogin}
              >
                Register Now
              </Button>
            )}
          </div>
        </div>
      </div>
      {/* Mobile View */}
      <div className="d-block d-md-none">
        <div className="p-3 p-lg-5 container-fluid text-white">
          <div className="row px-lg-5 d-flex justify-content-center">
            {workshopData.cardData.map((card, index) => (
              <div className="col-6 col-md-6 col-lg-3 mb-3" key={index}>
                <div
                  style={{ backgroundColor: "#1C1C1E", gap: 20 }}
                  className="card d-flex flex-row align-items-center p-3"
                >
                  <div
                    className="p-2"
                    style={{ backgroundColor: "#93939340", borderRadius: 2 }}
                  >
                    {card.icon}
                  </div>
                  <div>
                    <p
                      style={{ color: "white" }}
                      className="mb-0 ct-about-para-text "
                    >
                      {card.title}
                    </p>
                    <p
                      style={{ color: "white" }}
                      className="mb-0 ct-Banner-mode"
                    >
                      {card.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="container-fluid p-3 Timer-banner"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#393939",
              justifyContent: "space-between",
              borderRadius: "8px",
            }}
          >
            <div className=" mt-1">
              <p className="Banner-timer-button mb-0">Grab one-time deal at </p>
              <div className="d-flex align-items-center mt-2">
                <p className="ct-timer-size-32 " style={{ color: "#FF8761" }}>
                  @{workshopDetail?.itemId?.currency?.currency_code}{" "}
                  {workshopDetail?.itemId?.price}
                </p>
                <del className="ms-3 mb-2 ct-Workshop-del">
                  {workshopDetail?.itemId?.currency?.currency_code}{" "}
                  {workshopDetail?.itemId?.comparePrice}
                </del>
              </div>
            </div>
            <div className="align-items-center  d-flex  ">
              <div>
                <p className="Banner-timer-button mt-2 mb-1">
                  Registration ends in
                </p>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="d-flex align-items-end">
                    <p className="mb-0 ct-text-size-25 ">{timeLeft.days}</p>
                    <span className="ms-2 mb-1 ct-banner-22{">Days</span>
                  </div>
                  <div className=" d-flex align-items-end">
                    <p className="mb-0 ct-text-size-25 ">{timeLeft.hours}</p>
                    <span className="ms-2 mb-1  ct-banner-22{">Hours</span>
                  </div>
                  <div className=" d-flex align-items-end">
                    <p className="mb-0 ct-text-size-25 ">{timeLeft.minutes}</p>
                    <span className="ms-2 mb-1  ct-banner-22{ ">Minutes</span>
                  </div>
                  <div className=" text-center d-flex align-items-end">
                    <p className="mb-0 ct-text-size-25 ">{timeLeft.seconds}</p>
                    <span className="ms-2 mb-1  ct-banner-22{ ">Seconds</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid text-white"
          style={{
            position: "fixed",
            bottom: "0",
            left: "0",
            boxShadow: "#4e4e4e 0px -4px 8px",
            width: "100%",
            padding: "15px 15px",
            zIndex: 1000,
            backgroundColor: "black",
          }}
        >
          <div className="row align-items-center bg-black">
            <div
              style={{ width: "100vw" }}
              className="flex-direction-column d-flex align-items-center justify-content-evenly mb-md-5"
            >
              <div className="mt-1">
                <div>
                  <p className="mt-2 ct-Banner-part mb-1">
                    {workshopDetail?.itemId?.additionalDetail?.ratingSummary}
                  </p>
                </div>
              </div>
              {targetEndTime && new Date() > targetEndTime ? (
                <p
                  className="text-white px-5 py-3 Banner-timer-button"
                  style={{ backgroundColor: "grey", cursor: "not-allowed" }}
                >
                  Registration Closed
                </p>
              ) : (
                <Button
                  variant="gradient"
                  className="next-btn-small-dark px-sm-0 py-sm-0 text-white px-lg-5 py-lg-2 Banner-timer-button"
                  onClick={goToWorkshoplogin}
                  style={{ width: "70%" }}
                >
                  Register Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <a target="_blank" rel="noopener noreferrer">
        <div
          style={{
            position: "fixed",
            left: "0px",
            bottom: "400px",
            zIndex: 1000,
            width: "50px",
            height: "50px",
            backgroundColor: "#393939",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <img
            src={WhatsAppWorkShop}
            style={{ width: 40, height: 40 }}
            onClick={() => window.open(communityURL?.link, "_blank")}
          />
          <div></div>
        </div>
      </a>
    </div>
  );
};

export default WorkshopBannerCard;
