import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/system";
import CommunityBannerImage from "../../assets/community_image.png";

const CommunityBanner = () => {
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");
  const [communityURL, setCommunityURL] = useState(null);

  useEffect(() => {
    const fetchCommunityJoin = async () => {
      try {
        const response = await authService.getCommunityJoinChennal();
        setCommunityURL(response);
      } catch (error) {
        console.error("Error fetching community:", error);
      }
    };

    fetchCommunityJoin();
  }, []);

  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="mt-5 landing-page-main pb-3"
      id="applynow"
    >
      <div className="ct-top-30">
        <Fade direction="left">
          <div className="head">
            <div>
              <img
                src={CommunityBannerImage}
                alt="Casttree Award"
                width={"100%"}
                className="ct-background-transparent"
              />
            </div>
            <div className="my-5 home-text-center container">
              <div className="">
                <h1 className="ct-text-size-40">
                  Be part of our Creative Talents Community
                </h1>
                <div className="mt-4">
                  <p
                    className="ct-about-para-text"
                    style={{ fontWeight: 600, color: "#FFF" }}
                  >
                    Join our vibrant community to connect, collaborate, and
                    elevate your craft. Get platform updates, showcase your
                    skills and unlock new opportunities. What are you waiting
                    for?
                  </p>
                  <br />
                </div>
                <div className="ct-marign-right" style={{ marginTop: 3 }}>
                  <a
                    href={communityURL?.communitylink}
                    target="_blank"
                    className="my-3 join-community-now-fill ct-pointer"
                    style={{ cursor: "pointer", color: "#FFF" }}
                  >
                    <span style={{ position: "relative", bottom: 2 }}>
                      <WhatsAppIcon />
                    </span>{" "}
                    <span>Join Community</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default CommunityBanner;
