import React from "react";
import { useParams } from "react-router-dom";
import star from "../../assets/Star.png";
import { Container, Row, Col, Card } from "react-bootstrap";

const WorkshopHighlights = ({ workshopDetail }) => {
  const { workShopId } = useParams();

  return (
    <div className="p-3">
      <div className="d-flex col mt-4 align-items-center justify-content-center gap-2">
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
        <p className="ct-text-size-24 text-white  mb-0 d-flex align-items-center justify-content-center">
          Who is it for
        </p>
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
      </div>
      <Container className="mt-4">
        <Row className="justify-content-center gap-10">
          {workshopDetail?.itemId?.additionalDetail?.targetUsers?.map(
            (item, index) => (
              <Col
                xs={6}
                sm={6}
                md={4}
                lg={2}
                className="mb-4 ms-lg-3"
                key={index}
              >
                <Card
                  className="h-100"
                  style={{
                    backgroundColor: "#FFE6F2",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Body className="d-flex flex-column">
                    <div className="mb-3">
                      <img
                        style={{ width: 30, height: 30 }}
                        src={item.icon}
                        alt={item.title}
                      />
                    </div>
                    <div style={{ lineHeight: 1.2 }}>
                      <h5 className="ct-text-size-16 mb-0">{item.title}</h5>
                    </div>
                    <div style={{ color: "#555", flexGrow: 1 }}>
                      <p className="ct-text-size-12-med  mb-0 mt-2">
                        {item.body}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}
        </Row>
      </Container>
    </div>
  );
};

export default WorkshopHighlights;