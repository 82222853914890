import React from "react";
import star3 from "../../assets/star3.png";
import star5 from "../../assets/star5.png";
import quotes from "../../assets/Quotes.png";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// Import required modules
import { FreeMode, Pagination } from "swiper/modules";

export default function App() {
  const feedbackData = [
    {
      quote:
        "Aparna’s feedbacks and workshops are very helpful and she has helped me with her valuable feedback.",
      name: "Anushya Mohan",
      rating: 5,
    },
    {
      quote:
        "The insights I gained were incredible and boosted my confidence significantly.",
      name: "Rahul Sharma",
      rating: 5,
    },
    {
      quote:
        "The team was incredibly supportive and helped me achieve my goals.",
      name: "Priya Singh",
      rating: 5,
    },
    {
      quote:
        "The team was incredibly supportive and helped me achieve my goals.",
      name: "Priya Singh",
      rating: 5,
    },
    {
      quote:
        "The team was incredibly supportive and helped me achieve my goals.",
      name: "Priya Singh",
      rating: 5,
    },
  ];

  return (
    <>
      <div className="container text-white py-5">
        {/* Header */}
        <div className="text-center d-flex align-items-center justify-content-center mb-4 gap-2">
          <img src={star3} alt="star" width={24} height={24} />
          <h1 className="h4">Hear From Us</h1>
          <img src={star3} alt="star" width={24} height={24} />
        </div>
        <Swiper
          slidesPerView={3}
          spaceBetween={5}
          freeMode={true}
          pagination={false}
          breakpoints={{
            0: { slidesPerView: 1 }, 
            576: { slidesPerView: 1 },
            768: { slidesPerView: 1 }, 
            992: { slidesPerView: 2 }, 
            1200: { slidesPerView: 3 }, 
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
        >
          {feedbackData.map((feedback, index) => (
            <SwiperSlide key={index}>
              <div className="px-2">
                <div
                  className="p-3 m-2 bg-dark text-white rounded shadow-sm h-100"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 10,
                  }}
                >
                  <div className="d-flex align-items-start mb-3">
                    <img
                      src={quotes}
                      alt="quotes"
                      width={24}
                      className="me-2"
                    />
                  </div>
                  <div>
                    <div className="mb-2">
                      {Array(feedback.rating)
                        .fill(0)
                        .map((_, i) => (
                          <img
                            key={i}
                            src={star5}
                            alt="star"
                            width={16}
                            className="me-1"
                          />
                        ))}
                    </div>
                    <div>
                      <p className="small">{feedback.quote}</p>
                      <p className="small text-end fw-bold">{feedback.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
