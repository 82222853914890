import React, { useEffect, useState } from "react";
import logo from "../../assets/ct_trans.png";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import FooterData from "../workshop/footerData";
import WorkshopInfoDetail from "./workshopInfoDetail";
import { useParams } from "react-router-dom";
import workshopService from "../../services/workshopServices";
import { APP_CONSTANTS, ENV } from "../../const/app.const";
import HelpIcon from "@mui/icons-material/Help";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import authService from "../../services/authService";
import ApplyCouponCard from "../authService/applycoupencard";
import {trackFacebookEvent} from "../../services/facebookMetaEents";

const ENV_VARIABLES = APP_CONSTANTS[ENV];

const WorkshopCoupon = () => {
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const { workShopId } = useParams();
  const [workshopDetail, setWorkshopDetail] = useState(null);
  const [applySection, setApplySection] = useState(true);
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [savedRequestId, setSavedRequestId] = useState(null);
  const [loadingProceedToPay, setLoadingProceedToPay] = useState(false);
  const [initProceedToPay, setInitProceedToPay] = useState(false);
  const [communityURL, setCommunityURL] = useState(null);
  const [serviceRequestData, setServiceRequestData] = useState(null);
  const [workshopParticipantData, setWorkshopParticipantData] = useState(null);
  const [premiumDiscount, setPremiumDiscount] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCouponChange = (e) => {
    setCouponStatus("");
    const filteredText = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setCouponCode(filteredText);
  };

  useEffect(() => {
    const fetchCommunityJoin = async () => {
      try {
        const response = await authService.getCommunityJoinChennal();
        setCommunityURL(response);
      } catch (error) {
        console.error("Error fetching community:", error);
      }
    };

    fetchCommunityJoin();
  }, []);

  const navigateToSuccess = (paymentId) => {
    console.log("Payment ID", paymentId);
    navigate(`/workshop/payment/loading/${workShopId}/${paymentId}`);
  };

  useEffect(() => {
    console.log("Coupon workShopId", workShopId);
    getWorkshopDetail();
    workshopParticipant(workShopId);
  }, [workShopId]);

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const workshopParticipant = async (workshopId) => {
    try {
      const response = await workshopService.getPremiumDetail(workshopId);
      setWorkshopParticipantData(response);
      console.log("Workshop Participant Data:", response);
      if (response?.data?.memberShip) {
        setPremiumDiscount(parseFloat(response?.data?.discountValue));
      }
    } catch (error) {
      console.log("Error fetching workshop participant data:", error);
    }
  };

  const removeCoupon = () => {
    setDiscountedAmount(null);
    setCouponCode("");
    setCouponStatus("");
    setApplySection(true);
  };

  const applyCoupon = async () => {
    try {
      if (!couponCode) {
        setApplySection(true);
        setCouponStatus("Kindly enter coupon code");
        return;
      } else {
        setCouponStatus("");
        let payload = {
          sourceId: workshopDetail?.itemId?._id,
          sourceType: "serviceRequest",
          couponCode: couponCode?.toUpperCase(),
          module: "workShop",
          billingAmount: workshopDetail?.itemId?.price,
        };

        let couponResponse = await workshopService.applyCoupon(payload);
        if (couponResponse && couponResponse?.discountAmount) {
          let discountedAmount = couponResponse?.discountAmount;
          setApplySection(false);
          setDiscountedAmount(discountedAmount);
        } else {
          setApplySection(true);
          setCouponStatus("Invalid coupon code");
        }
      }
    } catch (error) {
      if (error?.response?.status === 406 && error?.response?.data?.message) {
        setApplySection(true);
        setCouponStatus(error?.response?.data?.message);
      } else {
        setApplySection(true);
        setCouponStatus("Invalid coupon code");
      }
    }
  };

  const getTotalPaymentAmount = () => {
    let price =
      discountedAmount !== null
        ? parseFloat(
            (workshopDetail?.itemId?.price - (discountedAmount ?? 0)).toFixed(2)
          )
        : workshopDetail?.itemId?.price;

    if (premiumDiscount) {
      const discount = (price * premiumDiscount) / 100;
      price = parseFloat((price - discount).toFixed(2));
    }

    return price;
  };

  const createServiceRequestAndPay = async () => {
    const user_id = localStorage.getItem("user_id");
    const userDetails = JSON.parse(localStorage.getItem("user_detail"));
    setIsButtonDisabled(true);
    try {
      const payload = {
        amount: getTotalPaymentAmount(),
        paymentMode: "Online",
        userId: user_id,
        itemId: workshopDetail?.itemId?._id,
        serviceRequest: {
          type: "workShop",
          status: "Initiated",
          itemId: workshopDetail?.itemId?._id,
          requestedToOrg: workshopDetail?.itemId?.orgId?._id,
          requestedToUser: workshopDetail?.profileData?.userId,
          requestedByOrg: userDetails?.organizationId,
        },
      };

      if (savedRequestId) {
        payload.serviceRequest.requestId = savedRequestId;
      }
      if (discountedAmount) {
        payload.discount = discountedAmount;
        payload.couponCode = couponCode?.toUpperCase();
      }
      if (premiumDiscount) {
        payload.premiumDiscount = premiumDiscount;
      }

      const response = await workshopService.paymentRequestInitiate(payload);
      console.log("response Service Request", response);
      setSavedRequestId(response?.serviceRequest?.request?._id);
      setServiceRequestData(response?.paymentData?._id);
      console.log("=========", serviceRequestData);
      console.log("response?.paymentData?._id)", response?.paymentData?._id);

      initiateRazorpayPayment(response);
    } catch (error) {
      setIsButtonDisabled(false);
    }
  };

  const handlePurchase = (orderId, amount) => {
    trackFacebookEvent("Purchase", {
      value: amount,
      currency: couponCode?.toUpperCase(),
      content_ids: [orderId],
      content_type: "workShop",
    });
  };

  const initiateRazorpayPayment = async (response) => {
    const user_id = localStorage.getItem("user_id");
    const userDetails = JSON.parse(localStorage.getItem("user_detail"));
    console.log("PAYEN", response);
    const rzp = new window.Razorpay({
      key: ENV_VARIABLES.RAZOR_PAY_KEY,
      currency: response?.paymentData?.currency_code,
      name: "Casttree",
      amount: response?.paymentData?.amount,
      order_id: response?.paymentData?.payment_order_id,
      prefill: {
        contact: userDetails?.user?.phoneNumber,
        name: userDetails?.user?.userName,
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (responseData) {
        return new Promise(async (resolve, reject) => {
          console.log("Payment success!", responseData);
          try {
            resolve();
            navigateToSuccess(response?.paymentData?._id);
            handlePurchase(
              response?.paymentData?._id,
              response?.paymentData?.amount
            );
          } catch (error) {
            console.log("Payment failed or canceled!", error);
            setLoadingProceedToPay(false);
            setInitProceedToPay(false);
            setIsButtonDisabled(false);
            reject(error);
          }
        });
      },
      modal: {
        ondismiss: function () {
          setLoadingProceedToPay(false); // Enable the button if the payment window is closed
          setInitProceedToPay(false);
          setIsButtonDisabled(false);
        },
      },
    });
    rzp.open();
  };

  return (
    <div className="work-shop-background-image web-full-height">
      <div className="container">
        <div className="ct-p- ct-d-flex-justify-center-home pt-4">
          <Fade direction="left" cascade>
            <div className="ct-pointer" onClick={() => navigate("/")}>
              <img
                src={logo}
                alt="Casttree Logo"
                width={120}
                className="pl-5"
              />
            </div>
          </Fade>
          <div
            onClick={() => {
              window.open(communityURL?.link);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              cursor: "pointer", // Adds a pointer cursor to indicate it's clickable
            }}
          >
            <WhatsAppIcon
              style={{
                fill: "white",
                fontSize: 25,
                position: "relative",
                bottom: 8,
              }}
            />
            <p className="ct-text-size-24" style={{ color: "#FFFFFF" }}>
              Help
            </p>
          </div>
        </div>
        <div className="ct-display-flex-mbl-unset pt-4">
          <div className="ws-card-first ct-bg-white ct-margin-20 mbl-mb-20">
            <WorkshopInfoDetail workshopDetail={workshopDetail} />
          </div>
          <ApplyCouponCard
            workshopDetail={workshopDetail}
            couponCode={couponCode}
            handleCouponChange={handleCouponChange}
            applyCoupon={applyCoupon}
            removeCoupon={removeCoupon}
            applySection={applySection}
            couponStatus={couponStatus}
            discountedAmount={discountedAmount}
            premiumDiscount={premiumDiscount}
            getTotalPaymentAmount={getTotalPaymentAmount}
            isButtonDisabled={isButtonDisabled}
            createServiceRequestAndPay={createServiceRequestAndPay}
          />
        </div>
      </div>
      <FooterData />
    </div>
  );
};

export default WorkshopCoupon;
