import "./App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./components/common/ProtectedRoute";
import UnProtectedRoute from "./components/common/UnProtectedRoute";
import ReactGA from "react-ga4";
import EventLanding from "./components/events/eventLanding";
import TermsConditions from "./components/common/termsConditions";
import PrivacyPolicy from "./components/common/privacyPolicy";
import RefundPolicy from "./components/common/refundPolicy";
import { APP_CONSTANTS, ENV } from "./const/app.const";
import { HelmetProvider } from "react-helmet-async";
import MetaPixel from "./const/metaPixel";
import WorkshopMainList from "./components/workshop/workshopMain";
import WorkshopLogin from "./components/workshop/workshopLogin";
import WorkshopOnboarding from "./components/workshop/workshopOnboarding";
import WorkshopCoupon from "./components/workshop/workshopCoupon";
import WorkshopSuccess from "./components/workshop/workshopSuccess";
import WorkshopLoading from "./components/workshop/paymentLoading";
import WorkshopLanding from "./components/workshop/wsLanding";
import posthog from "posthog-js";
import CreateEvent from "./components/events/createEvent";
import Login from "./components/events/login";
import { Navigate } from "react-router-dom";
// import  { useEvents,updateUser, logoutUser } from "./services/useEvents";

// import posthog from 'posthog-js';
import useEvents, { updateUser, logoutUser } from "./services/useEvents";
import WorkshopLearn from "./components/workshop/wsLearn";
import WsFeedbackPage from "./components/workshop/wsFeedbackPage";
import ProfileDetailScreen from "./components/profile/profileDetail";
import CSAEPolicy from "./components/common/csae";
import useDeepLinkRedirect from "./services/deeplink";
import MobileRedirection from "./services/mobileRedirection";

const ENV_VARIABLES = APP_CONSTANTS[ENV];
const GOOGLE_ANALYTICS_ID = ENV_VARIABLES.GOOGLE_ANALYTICS_ID;
const MICROSOFT_CLARITY_ID = ENV_VARIABLES.MICROSOFT_CLARITY_ID;
ReactGA.initialize(GOOGLE_ANALYTICS_ID, { debug: true });


const App = () => {
  const useClarity = () => {
    console.log("MICROSOFT_CLARITY_ID", MICROSOFT_CLARITY_ID);
    useEffect(() => {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", MICROSOFT_CLARITY_ID);
    }, [MICROSOFT_CLARITY_ID]);
  };

  const DeepLinkHandler = () => {
    useDeepLinkRedirect();
    // return null;
  };
  
  const GATracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      setInitialized(true);
  
      return () => {
        posthog.reset();
      };
    }, []);
    useEffect(() => {
      if (initialized) {
        ReactGA.send(location.pathname + location.search);
      }
    }, [initialized, location]);
  };

  

  // useClarity();
  // useDeepLinkRedirect();
  // useEvents();
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]')?.forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href"))?.scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <HelmetProvider>
      <div
        className="App"
        style={{ backgroundColor: "#000", background: "#000", height: "100vh" }}
      >
        <Router>
          <MetaPixel />
          <GATracker />
          {/* <DeepLinkHandler />  */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/ct/*" element={<MobileRedirection />} />
            {/* <Route path="/workshops" element={<WorkshopMainList />} /> */}
            <Route path="/events" element={<EventLanding />} />
            <Route path="/event" element={<EventLanding />} />
            {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/event-create" element={<CreateEvent />} /> */}
            {/* <Route path="/eventAppScreen" element={<DownloadAppScreen />} /> */}
            {/* <Route
              path="/workshop-login"
              element={<UnProtectedRoute component={<WorkshopLogin />} />}
            />
            <Route
              path="/workshop-create"
              element={<ProtectedRoute component={<WorkshopOnboarding />} />}
            /> */}
            {/* <Route path="/eventssuccessfully" element={<EventLanding />} /> */}
            <Route path="/termsConditions" element={<TermsConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/refundPolicy" element={<RefundPolicy />} />
            <Route path="/csaePolicy" element={<CSAEPolicy />} />
            <Route path="/workshops" element={<WorkshopMainList />} />
            <Route path="/workshop/:workShopId" element={<WorkshopLanding />} />
            {/* <Route path="/WorkshopMainLanding" element={<WorkshopMainLanding />} /> */}
            <Route
              path="/workshop/login/:workShopId"
              element={<WorkshopLogin />}
            />
            <Route
              path="/workshop/create/:workShopId"
              element={<WorkshopOnboarding />}
            />
            <Route
              path="/workshop/coupon/:workShopId"
              element={<WorkshopCoupon />}
            />
            <Route path="/workshop/payment/loading/:workShopId" element={<WorkshopLoading />} />
            <Route
              path="/workshop/payment/loading/:workShopId/:paymentId"
              element={<WorkshopLoading />}
            />
            <Route
              path="/workshop/success/:workShopId"
              element={<WorkshopSuccess />}
            />
            <Route
              path="/workshop/wsLearn/:workShopId"
              element={<WorkshopLearn />}
            />
            <Route path="/profile/:username" element={<ProfileDetailScreen />} />
            <Route path="/wsFeedbackPage" element={<WsFeedbackPage />} />
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
