import React, { useState, useEffect } from "react";
import logo from "../../assets/ct_trans.png";
// import BGart from "../assets/BGart.png"
import { Fade } from "react-awesome-reveal";
import { useNavigate, useParams } from "react-router-dom";
import WorkshopInfoDetail from "./workshopInfoDetail";
import FooterData from "../workshop/footerData";
import UserOnboarding from "../authService/userOnboarding";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import authService from "../../services/authService";
import workshopService from "../../services/workshopServices";
import SEO from "../common/seo";

const WorkshopOnboarding = () => {
  const navigate = useNavigate();
  const { workShopId } = useParams();
  const [workshopDetail, setWorkshopDetail] = useState(null);
  const [communityURL, setCommunityURL] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getWorkshopDetail();
  }, [workShopId]);

  useEffect(() => {
    const fetchCommunityJoin = async () => {
      try {
        const response = await authService.getCommunityJoinChennal();
        setCommunityURL(response);
      } catch (error) {
        console.error("Error fetching community:", error);
      }
    };

    fetchCommunityJoin();
  }, []);

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const goToWorkshopCoupon = () => {
    navigate(`/workshop/coupon/${workShopId}`);
  };

  return (
    <div className="work-shop-background-image web-full-height">
      <SEO
        title="🎤 Casttree Singing Workshops | Learn from Top Singing Experts and Playback Singers"
        description="Join Casttree’s exclusive online singing workshops led by top playback singers. Learn vocal techniques, improvisation, and performance skills in live, interactive sessions. Perfect for aspiring singers worldwide! 🎶✨"
      />
      <div className="container">
        <div className="ct-p- ct-d-flex-justify-center-home pt-4">
          <Fade direction="left" cascade>
            <div className="ct-pointer" onClick={() => navigate("/")}>
              <img src={logo} alt="Casttree Logo" width={120} className="pl-5" />
            </div>
          </Fade>
          <div
            onClick={() => {
              window.open(communityURL?.link);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              cursor: "pointer", // Adds a pointer cursor to indicate it's clickable
            }}
          >
            <WhatsAppIcon
              style={{
                fill: "white",
                fontSize: 25,
                position: "relative",
                bottom: 8,
              }}
            />
            <p className="ct-text-size-24" style={{ color: "#FFFFFF" }}>
              Help
            </p>
          </div>
        </div>
        <div className="ct-display-flex-mbl-unset pt-4">
          <div className="ws-card-first ct-bg-white ct-margin-20 mbl-mb-20">
            <WorkshopInfoDetail workshopDetail={workshopDetail} />
          </div>
          <UserOnboarding
            workShopId={workShopId}
            goToWorkshopCoupon={goToWorkshopCoupon}
          />
        </div>
      </div>
      <FooterData />
    </div>
  );
};

export default WorkshopOnboarding;