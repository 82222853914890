import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import toast from "react-hot-toast";
import { useTriggeredEvent } from "../../services/useTriggeredEvents";
import authService from "../../services/authService";
import { CasttreeEventType } from "../../services/eventConstants";

const UserOnboarding = ({ workShopId, goToWorkshopCoupon }) => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [genderData, setGenderData] = useState("");
  const { handleTriggeredEvents } = useTriggeredEvent();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const user_id = localStorage.getItem("user_id");
    const userDetail = await authService.userDetailUpdate(user_id);
    setMobileNumber(userDetail?.phoneNumber);
    setFullName(userDetail?.userName);
    setEmail(userDetail?.emailId);
    setGenderData(userDetail?.gender);
  };

  const handleFullNameChange = (e) => {
    const fullName = e.target.value;
    setFullName(fullName);
    setFullNameError("");
  };

  const handleEmailChange = (e) => {
    const emailId = e.target.value;
    setEmail(emailId);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailId && !emailPattern.test(emailId)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const saveUser = async () => {
    await updateUserDetails();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateUserDetails = async () => {
    const userLoginOrgDetail = localStorage.getItem("user_otpResponse_data");
    let isValid = true;
    try {
      if (!fullName) {
        setFullNameError("Please enter a Fullname");
        isValid = false;
      }

      if (!isValid) return;
      const userData = {
        organizationId: userLoginOrgDetail.trim().replace(/"/g, ""),
        emailId: email,
        userName: fullName,
        phoneCountryCode: "+91",
        phoneNumber: mobileNumber,
        gender: genderData,
      };
      const updatedUserDetail = await authService.updateUserData(userData);
      localStorage.setItem("user_detail", JSON.stringify(userData));
      toast.success("User Updated Successfully");
      await handleTriggeredEvents(CasttreeEventType.NEW_USER_CREATED_SUCCESS, {
        phoneNumber: userData.phoneNumber,
        // Add other additional details as needed
      });
      goToWorkshopCoupon();
    } catch (error) {
      console.log("User Create API Error", error);
    }
  };

  return (
    <div className="ws-card-second ct-bg-white">
      <div className="pb-2">
        <p className="ct-cirka-size-26 mb-1">Tell us details about you</p>
        <p className="ct-text-size-14-gray">
          Complete your purchase by providing your payment details.
        </p>
      </div>

      <div>
        <div className="mt-3">
          <p className="copy-right-text mb-2" style={{ fontWeight: 600, color: "#FFFF" }}>
            Mobile Number
          </p>
          <div className="ct-display-inline-block" style={{ position: "relative" }}>
            <input
              type="number"
              placeholder="enter your mobile number"
              defaultValue={mobileNumber}
              className="ct-input-box"
              style={{ pointerEvents: "none", color: "#FFFF", fontWeight: 600 }}
            />
            <CheckCircleOutlineOutlinedIcon
              className="check-icon-green"
              style={{
                position: "absolute",
                top: "50%",
                fill: "green",
                right: 10,
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <p className="copy-right-text mb-2" style={{ fontWeight: 600, color: "#FFFF" }}>
            Name
          </p>
          <div className="">
            <input
              type="text"
              value={fullName}
              placeholder="enter your name"
              className="ct-input-card"
              onChange={handleFullNameChange}
            />
          </div>
          <div className="pt-2">
            <p className="text-danger ct-text-14">{fullNameError}</p>
          </div>
        </div>
        <div className="mt-3">
          <p className="copy-right-text mb-2" style={{ fontWeight: 600, color: "#FFFF" }}>
            Email ID
          </p>
          <div className="">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="enter your email ID"
              className="ct-input-card"
            />
          </div>
          <div className="pt-2">
            <p className="text-danger ct-text-14">{emailError}</p>
          </div>
        </div>

        <div className="mt-4">
          <button
            className="event-form-next-btn"
            onClick={saveUser}
            disabled={!fullName || !email}
            style={{
              background: fullName && email
                ? "linear-gradient(to right, #EE35FF 0%, #FF8761 100%)"
                : "gray",
              color: "white"
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserOnboarding;