import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import logoImage from "../../assets/ct_trans.png";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WorkshopNavbarComponent = () => {
  const [communityURL, setCommunityURL] = useState(null);
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header-nav");
      if (window.scrollY > 200) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    authService.doLogout();
  };

  useEffect(() => {
    const fetchCommunityJoin = async () => {
      try {
        const response = await authService.getCommunityJoinChennal();
        setCommunityURL(response);
      } catch (error) {
        console.error("Error fetching community:", error);
      }
    };

    fetchCommunityJoin();
  }, []);

  const navigateUserProfile = () => {
    navigate(`/profile`);
  };

  return (
    <>
      <Navbar
        className="header-nav navbar-dark workshop-landing-main"
        collapseOnSelect
        expand="lg"
        style={{ backgroundColor: "#000", padding: "20px 0px" }}
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5"
            style={{ paddingLeft: 30, cursor: "pointer" }}
            onClick={() => navigate("/workshops")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </Navbar.Brand>
        </Fade>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="custom-toggler"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul
            className="navbar-nav ml-auto mr-lg-5 ml-lg-auto"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              paddingRight: 30,
              fontWeight: "bold",
            }}
          >
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="text-decoration-none px-lg-3 ct-pointer"
                  to="highlights"
                  smooth={true}
                  duration={500}
                  style={{ color: window.innerWidth < 992 ? "#fff" : "#fff" }}
                >
                  Highlights
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="text-decoration-none px-lg-3 ct-pointer"
                  to="mentor"
                  smooth={true}
                  duration={500}
                  style={{ color: window.innerWidth < 992 ? "#fff" : "#fff" }}
                >
                  Mentor
                </Link>
              </li>
            </Fade>

            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="text-decoration-none px-lg-3 ct-pointer"
                  to="faqs"
                  smooth={true}
                  duration={500}
                  style={{ color: window.innerWidth < 992 ? "#fff" : "#fff" }}
                >
                  FAQs
                </Link>
              </li>
            </Fade>
          </ul>
          <a
            href={communityURL?.link}
            target="_blank"
            style={{ cursor: "pointer", color: "#FFF", }}
          >
            <Fade direction="right" cascade>
              <span
              style={{paddingLeft: 10}}
              >
                <WhatsAppIcon />
              </span>
            </Fade>
          </a>
          {ctToken && (
            <Nav>
              <Fade direction="right" cascade>
                <Nav.Link className="px-lg-3 ct-pointer ps-3" onClick={handleLogout}>
                  <LogoutOutlinedIcon />
                </Nav.Link>
              </Fade>
            </Nav>
          )}
        </Navbar.Collapse>
        
      </Navbar>
    </>
  );
};

export default WorkshopNavbarComponent;
