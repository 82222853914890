import star from "../../assets/Star.png";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import workshopService from "../../services/workshopServices";

const WorkshopDateTimeCard = ({ workshopDetail }) => {
  const { workShopId } = useParams();

  return (
    <div>
      <div
        className="d-flex col mt-4"
        style={{ alignItems: "center", justifyContent: "center", gap: 10 }}
      >
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
        <p
          className="ct-text-size-24 text-white h3 mb-0"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          What you will learn
        </p>
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
      </div>
      <div className="container py-2 text-white">
        <div className="row justify-content-center">
          {workshopDetail?.itemId?.additionalDetail?.highlights?.map(
            (card, index) => (
              <div
                key={index}
                style={{ padding: "20px" }}
                className={`col-lg-4 col-md-6 col-12 ${
                  index ===
                  workshopDetail?.itemId?.additionalDetail?.highlights.length -
                    2
                    ? " "
                    : ""
                }`}
              >
                <div
                  className="card-custom"
                  style={{
                    backgroundImage: `url(${card.icon})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <h5 className="ct-WorkShop-size-20">{card.title}</h5>
                  <p className="Workshop-sub-title" style={{ width: "80%" }}>
                    {card.body}
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkshopDateTimeCard;