import { useEffect } from "react";
import posthog from "posthog-js";

export const updateUser = async (phoneNumber: any) => {
  posthog.capture("$set", {
    $set: { name: phoneNumber },
  });
};

export const logoutUser = async () => {
  // posthog.reset();
  console.log("before resetSessionId");
  //posthog.resetSessionId();
  console.log("after resetSessionId");
};

const useEvents = () => {
  const publishEvents = async (eventData) => {
    posthog.capture(eventData.name, eventData);
  };

  useEffect(() => {
    const user_details = JSON.parse(localStorage.getItem("user_detail"));
    const mobileNumber = user_details?.phoneNumber || "";
    // Initialize PostHog
    // posthog.init("phc_38zsJb6xbdEkGxqBFPV8uBY98jFF6H0tSPTjud7SfX4", {
    //   api_host: "https://posthog.casttree.in",
    // });

    posthog.capture("$set", {
      $set: { name: mobileNumber },
    });
    // Track page views
    // posthog.capture("$pageview", {
    //   distinct_id: mobileNumber,
    //   current_url: window.location.href,
    //   referrer: document.referrer,
    //   path: window.location.pathname,
    // });

    // Cleanup function to reset PostHog on unmount
    return () => {
      posthog.reset();
    };
  }, []);

  return { publishEvents };
};

export default useEvents;
