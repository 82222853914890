import React from "react";

const SocialMedia = ({ socialMedia = [] }) => {
  const getSocialMediaImage = (platform, link) => {
    if (!link) return null;

    switch (platform) {
      case "facebook":
        return (
          <div onClick={() => window.open(link.startsWith('http') ? link : `https://${link}`, "_blank")}>
            <img
              src="https://tecxprts3qa.s3.amazonaws.com/1707322778596facebook.png"
              alt="Facebook"
              width={40}
              height={40}
            />
          </div>
        );
      case "instagram":
        return (
          <div onClick={() => window.open(`https://${link}`, "_blank")}>
            <img
              src="https://tecxprts3qa.s3.amazonaws.com/1707322772702instagram.png"
              alt="Instagram"
              width={40}
              height={40}
            />
          </div>
        );
      case "x":
        return (
          <div onClick={() => window.open(`https://${link}`, "_blank")}>
            <img
              src="https://tecxprts3qa.s3.amazonaws.com/1707322788008twitter%20%281%29.png"
              alt="X (Twitter)"
              width={40}
              height={40}
            />
          </div>
        );
      case "youtube":
        return (
          <div onClick={() => window.open(`https://${link}`, "_blank")}>
            <img
              src="https://tecxprts3qa.s3.amazonaws.com/1707322792449youtube.png"
              alt="YouTube"
              width={40}
              height={40}
            />
          </div>
        );
      case "snapchat":
        return (
          <div onClick={() => window.open(`https://${link}`, "_blank")}>
            <img
              src="https://tecxprts3qa.s3.amazonaws.com/1707322788293snapchat.png"
              alt="Snapchat"
              width={40}
              height={40}
            />
          </div>
        );
      default:
        return null;
    }
  };

  // Check if there is at least one valid link
  const hasValidLink = socialMedia.some(social => social.link);

  if (!hasValidLink) {
    return null;
  }

  return (
    <div className="px-2 col pt-3 pb-3">
      <div className="px-2 text-white w-100 h-100 d-flex align-items-end">
        <div
          className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
          style={{
            backgroundColor: "#151515",
            borderRadius: 10,
            gap: "10px",
          }}
        >
          <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
            Social media
          </h2>
          <div className="d-flex flex-wrap">
            {socialMedia.map((social, index) => {
              const { platform, link } = social;
              return (
                link && (
                  <div
                    key={index}
                    className="d-flex flex-row justify-content-start mt-2"
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      {getSocialMediaImage(platform, link)}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
