import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import logoImage from "../../assets/ct_trans.png";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import { Link } from "react-scroll"; // Import Link and animateScroll from react-scroll
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import authService from "../../services/authService";
// Remove import WorkshopMainList; this is not needed as we're navigating by route, not component

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const NavbarComponent = () => {
  const navigate = useNavigate(); 

  const ctToken = localStorage.getItem("access_token");
  const [showRulesDialog, setShowRulesDialog] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header-nav");
      if (window.scrollY > 80) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateToLogin = async () => {
    if (ctToken) {
      try {
        const response = await authService.getProjectList();
        if (response?.data?.length > 0) {
          navigate(`/event-create?tab=${3}`);
        } else {
          navigate(`/event-create?tab=${2}`);
        }
      } catch (error) {
        console.error("Error fetching project list:", error);
      }
    } else {
      navigate("/login");
    }
  };

  const handleClose = () => {
    setShowRulesDialog(false);
  };

  const handleLogout = () => {
    authService.doLogout();
  };

  const openRulesDialog = () => {
    setShowRulesDialog(true);
  };

  const goToWorkshopMainList = () => {
    navigate("/workshop"); 
  };

  return (
    <>
      <Navbar
        className="header-nav navbar-light landing-page-main"
        fixed="top"
        collapseOnSelect
        style={{ color: "#FFF", padding: 10 }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5"
            style={{ paddingLeft: 30, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5"
            />
          </Navbar.Brand>
        </Fade>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ color: "#FFF", marginRight: 30 }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul
            className="navbar-nav ml-auto mr-lg-5 ml-lg-auto"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 text-white ct-pointer"
                  to="Awards"
                  smooth={true}
                  duration={500}
                >
                  Awards
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 text-white ct-pointer"
                  to="Jury"
                  smooth={true}
                  duration={500}
                >
                  Jury
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 text-white ct-pointer"
                  to="Flashback"
                  smooth={true}
                  duration={500}
                >
                  Flashback
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 text-white ct-pointer"
                  to="Sponsors"
                  smooth={true}
                  duration={500}
                >
                  Sponsors
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li className="nav-item">
                <Link
                  className="nav-link px-lg-3 text-white ct-pointer"
                  to="AboutUs"
                  smooth={true}
                  duration={500}
                >
                  About us
                </Link>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li
                className="nav-item"
                style={{ paddingTop: 9 }}
                onClick={openRulesDialog}
              >
                <p
                  className="nav-link px-lg-3 text-white ct-pointer"
                  smooth={true}
                  duration={500}
                >
                  Rules
                </p>
              </li>
            </Fade>
            <Fade direction="right" cascade>
              <li
                className="nav-item"
                style={{ paddingTop: 9 }}
                onClick={goToWorkshopMainList}
              >
                <p
                  className="nav-link px-lg-3 text-white ct-pointer"
                  smooth={true}
                  duration={500}
                >
                  Workshop
                </p>
              </li>
            </Fade>
          </ul>

          {/* <Nav>
            <Fade direction="right" cascade>
              <Nav.Link
                className="px-lg-3"
                rel="noopener noreferrer"
                target="_blank"
                onClick={navigateToLogin}
              >
                <p className="apply-now-btn mb-0 apply-now-border ct-pointer">
                  <span className="apply-now-span">Apply Now</span>
                </p>
              </Nav.Link>
            </Fade>
          </Nav>
          {ctToken && (
            <Nav>
              <Fade direction="right" cascade>
                <Nav.Link className="px-lg-3 ct-pointer" onClick={handleLogout}>
                  <LogoutOutlinedIcon
                    style={{ fill: "white", fontSize: 20, cursor: "pointer" }}
                  />
                </Nav.Link>
              </Fade>
            </Nav>
          )} */}
        </Navbar.Collapse>

        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={showRulesDialog}
        >
          <p
            className="ct-text-18 ct-text-weight-600 ct-text-light p-3 pt-3"
            id="customized-dialog-title"
          >
            Rules and regulations
          </p>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#FFF",
            }}
            className="ct-pointer"
          >
            <HighlightOffIcon className="ct-text-18" />
          </IconButton>
          <DialogContent dividers>
            <div className="dialog-custom-rules">
              <p className="ct-text-14 ct-text-light">
                {/* Rules content here */}
              </p>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
