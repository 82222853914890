import { useEffect } from "react";

const MobileRedirection = () => {
  useEffect(() => {
    window.location.replace("https://onelink.to/a9kcfx");
  }, []);

  return null;
};

export default MobileRedirection;
