import React, { useState } from "react";

const Awards = ({ awards = [], maxLines = 2 }) => {
  const [showMore, setShowMore] = useState(false);
  const [expandedAwards, setExpandedAwards] = useState({});

  const toggleShowMore = (index) => {
    setExpandedAwards((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Hide the whole component if no awards data is available
  if (!awards || awards.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="px-2 col pt-3">
        <div className="px-2 text-white w-100 h-100 d-flex align-items-end">
          <div
            className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
            style={{
              backgroundColor: "#151515",
              borderRadius: 10,
              gap: "10px",
            }}
          >
            <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
              Awards and Certificates
            </h2>

            {awards
              .slice(0, showMore ? awards?.length : 2)
              .map((award, index) => (
                <div key={index}>
                  <div
                    className="d-flex flex-row justify-content-start w-100 h-100"
                    style={{
                      gap: "20px",
                    }}
                  >
                    <div
                      className="d-flex bg-black justify-content-center align-items-center"
                      style={{
                        width: 70,
                        height: 70,
                        borderRadius: 10,
                        border: "1px solid white",
                      }}
                    >
                      <img
                        src={award?.media[0]?.media_id?.media_url} // Only the logo image
                        alt="award logo"
                        style={{
                          width: 70,
                          height: 70,
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-column justify-content-start align-items-start"
                      style={{
                        width: "70%",
                      }}
                    >
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        {award?.title}
                      </p>
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {award?.issuerName}
                      </p>
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {new Date(award?.issueDate).toLocaleDateString("en-US", {
                          month: "short",
                          year: "numeric",
                        })}
                      </p>
                      {award?.description && (
                        <div
                          className="d-flex flex-column pb-2"
                          style={{ width: "100%" }}
                        >
                          <p
                            className="ct-text-size-14 mb-0"
                            style={{
                              color: "grey",
                              textAlign: "left",
                              display: "-webkit-box",
                              WebkitLineClamp: expandedAwards[index]
                                ? "none"
                                : maxLines,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {award?.description}
                          </p>
                          {award?.description.split(" ").length >
                            maxLines * 10 && (
                            <button
                              onClick={() => toggleShowMore(index)}
                              style={{
                                background: "none",
                                border: "none",
                                color: "grey",
                                cursor: "pointer",
                                padding: 0,
                                textAlign: "right",
                              }}
                            >
                              {expandedAwards[index] ? "Show Less" : "Show More"}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {awards?.length > 2 && (
              <div className="mt-2 ct-text-size-14 d-flex justify-content-center align-items-center w-100">
                <button
                  onClick={() => setShowMore(!showMore)}
                  style={{
                    backgroundColor: "transparent",
                    color: "grey",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showMore ? "Show less" : "Show more"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
