import React from "react";
import ExpertPage from "./expertPage";
import OfferingsPage from "./offeringsPage";
const WsFeedbackPage = () => {
  return (
    <div className="wsFeedbackPage" style={{ gap: 30 }}>
      <div className="expertPage">
        <ExpertPage />
      </div>
      <div className="col Offeringpage">
        <OfferingsPage />
      </div>
    </div>
  );
};

const styles = {};
export default WsFeedbackPage;
