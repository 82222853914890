import React from "react";
import { Fade } from "react-awesome-reveal";
import AVM from "../../assets/avm.png";
import VELS from "../../assets/vels.png";
import MINIMINI from "../../assets/minmini.png";
import OTS from "../../assets/ots_logo.png";
import MM from "../../assets/mm_logo.jpeg";
import RHYTHMIC from "../../assets/rhythmic_beat_dance.png";
import POORVIKA from "../../assets/poorvika.png";
import MUYBRIDGE from "../../assets/muybridge.png";
import SF from "../../assets/sf.svg";
import KUMUDAM from "../../assets/kumudam.png";
import TAKEOKAY from "../../assets/take_okay.png";
import PALLIKOODAM from "../../assets/pallikoodam_logo.png";

const MeetOurSponsors = () => {
  const windowWidth = window.innerWidth;
  let avmImageSize = windowWidth >= 1024 ? 90 : 90; // AVM image size based on window width
  let imageSize = { width: 150 }; // Default image size
  if (windowWidth >= 1024) {
    imageSize = { width: 150 }; // Adjust image size for large screens
  } else if (windowWidth >= 768 && windowWidth < 1024) {
    imageSize = { width: 150, marginRight: 30 }; // Adjust image size for tablet-sized screens
  } else if (windowWidth >= 320 && windowWidth < 450) {
    imageSize = { width: 150, marginRight: 0 }; // Adjust image size for tablet-sized screens
  }

  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="container mt-5 landing-page-main"
      id="Sponsors"
    >
      <div className="ct-top-30">
        <div className="head">
          <div className="head-div mx-auto ct-center-fixed mbl-mb-30">
            <Fade direction="top-left" cascade>
              <section className="div-1 home-text-center">
                <h1 className="ct-title">MEET OUR SPONSORS</h1>
                <p className="ct-para-text">The people behind the scenes.</p>
              </section>
            </Fade>
          </div>
          {/* column-container meet-display-flex */}
          <div className="mt-4 ct-center-fixed-mbl web-mb-30">
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center", }}
            >
              <a
                href="https://fv4b.short.gy/awGOD5"
                target="_blank"
              >
                <img
                  src={MM}
                  alt="On The Streets of Chennai"
                  style={{ width: 86 }}
                  className="ct-pointer"
                />
              </a>
              <p
                className="pt-2 ct-para-text"
                style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
              >
                Maali and Manvi Movie Makers
              </p>
              <p
                className="pt-1 ct-para-text"
                style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
              >
                Production House Sponsor
              </p>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center", }}
            >
              <a
                href="https://fv4b.short.gy/wwBhqp"
                target="_blank"
              >
                <img
                  src={KUMUDAM}
                  alt="KUMUDAM"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-second">
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Kumudam
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Media Sponsor
                </p>
              </div>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center", }}
            >
              <a
                href="https://fv4b.short.gy/s7Qf0E"
                target="_blank"
              >
                <img
                  src={VELS}
                  alt="Vels University"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-third">
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Vels University
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Venue Sponsor
                </p>
              </div>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center", }}
            >
              <a
                href="https://fv4b.short.gy/M3qcTY"
                target="_blank"
              >
                <img
                  src={OTS}
                  alt="On The Streets of Chennai"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="" style={{ position: "relative", top: 2 }}>
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  On The Streets of Chennai
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Lead Sponsor
                </p>
              </div>
            </div>
            {/* <img src={AVM} alt='AVM Studio' style={{ width: avmImageSize, marginRight: imageSize.marginRight }} />
                            <img src={MINIMINI} alt='MINIMINI' style={{ ...imageSize, marginRight: 0 }} /> */}
          </div>
          <div className="mt-4 ct-center-fixed-mbl web-mb-30">
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center",}}
            >
              <a
                href="https://fv4b.short.gy/jDun59"
                target="_blank"
              >
                <img
                  src={RHYTHMIC}
                  alt="Rhythmic Beat Dance Court"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-five" >
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Rhythmic Beat Dance Court
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Associate Sponsor
                </p>
              </div>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center", }}
            >
              <a
                href="https://fv4b.short.gy/hxIShz"
                target="_blank"
              >
                <img
                  src={POORVIKA}
                  alt="Poorvika Mobiles"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-six">
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Poorvika Mobiles
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Associate Sponsor
                </p>
              </div>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center",}}
            >
              <a
                href="https://fv4b.short.gy/a9KT9a"
                target="_blank"
              >
                <img
                  src={MUYBRIDGE}
                  alt="Muybridge Film School"
                  style={{ width: 86 }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-seven">
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Muybridge Film School
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Associate Sponsor
                </p>
              </div>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center"}}
            >
              <a
                href="https://fv4b.short.gy/n7EMwN"
                target="_blank"
              >
                <img
                  src={SF}
                  alt="Shortfundly"
                  width={51}
                  // style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-eight">
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Shortfundly
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Community Sponsor
                </p>
              </div>
            </div>
            {/* <img src={AVM} alt='AVM Studio' style={{ width: avmImageSize, marginRight: imageSize.marginRight }} />
                            <img src={MINIMINI} alt='MINIMINI' style={{ ...imageSize, marginRight: 0 }} /> */}
          </div>
          <div className="mt-4 ct-center-fixed-mbl web-mb-30">
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center",}}
            >
              <a
                href="https://fv4b.short.gy/gCW0kc"
                target="_blank"
              >
                <img
                  src={TAKEOKAY}
                  alt="Take Okay Chennai"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-nine" >
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  Take Okay
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Community Sponsor
                </p>
              </div>
            </div>
            <div
              className="ct-pointer mbl-mb-30 web-mr-30"
              style={{ textAlign: "center",}}
            >
              <a
                href="https://fv4b.short.gy/hbSWI2"
                target="_blank"
              >
                <img
                  src={PALLIKOODAM}
                  alt="The Pallikoodam"
                  style={{ ...imageSize }}
                  className="ct-pointer"
                />
              </a>
              <div className="sponsors-ten" >
                <p
                  className="pt-2 ct-para-text"
                  style={{ fontWeight: "800", color: "#FFF", fontSize: 17 }}
                >
                  The Pallikoodam
                </p>
                <p
                  className="pt-1 ct-para-text"
                  style={{ fontWeight: "600", color: "rgb(155 155 155)" }}
                >
                  Associate Sponsor
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetOurSponsors;
