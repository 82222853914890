import React from "react";
import NavbarComponent from "../events/navbar";
import Banner from "./banner";
import Categories from "./categories";
import WhatElse from "./whatelse";
import FlashBack from "./flashback";
import AboutUs from "./aboutUs";
import Testmonial from "./testimonial";
import MeetOurSponsors from "./meetOurSponsors";
import ApplyNow from "./applyNow";
import Footer from "./footer";
import SEO from "../common/seo";
import JurySection from "./jurySection";
import CommunityBanner from "./communityBanner";

const EventLanding = () => {
  return (
    <div className="landing-page-main">
      <SEO
        title="Casttree Events | India's Biggest Short Films, Music & Dance Festival and awards"
        description="Short Film, Music & Dance Competition is a great way to find the talent within you. Submit your Short Film, Music & Dance see where you stand up among pan india. Casttree events and awards 2024 will be held in Vels University, Chennai, Tamilnadu."
      />
      <NavbarComponent />
      <Banner />
      {/* <ThePanel /> */}
      <Categories />
      <JurySection />
      <WhatElse />
      <FlashBack />
      <MeetOurSponsors />
      <CommunityBanner />
      <AboutUs />
      <Testmonial />
      <ApplyNow />
      <Footer showSocialMediaLinks={true} />
    </div>
  );
};

export default EventLanding;
