import React from "react";
import logo from "../../assets/ct_trans.png";
import star5 from "../../assets/star5.png";
import star3 from "../../assets/star3.png";
import expert1 from "../../assets/expert1.png";
import { Fade } from "react-awesome-reveal";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ComputerIcon from "@mui/icons-material/Computer";
import TranslateIcon from "@mui/icons-material/Translate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const ExpertPage = () => {
  const experts = [
    {
      name: "Aparna Narayanan",
      role: "Playback singer",
      rating: "4.8/5",
      sessions: "78+ sessions",
      description:
        "Aparna Narayanan is a playback singer from Tamil Nadu, recognized for her distinct voice in Tamil cinema. She gained popularity after participating in Super Singer, where her talent caught the attention of music fans and professionals in Kollywood.",
      skills: ["Voice culture", "Playback Singing"],
      image: expert1,
    },
    // Add more experts here if needed
  ];

  return (
    <div className="">
      {experts.map((expert, index) => (
        <div className="containers row-lg fixed-side" key={index}>
          <div
            className="p-lg-5 d-none d-md-block p-3"
            style={{
              background: "linear-gradient(270deg, #313131 0%, #000000 100%)",
              width: "35%",
              height: "100vh",
              borderRight: "2px solid",
              borderImage: "linear-gradient(to bottom, #ff6a00, #d100ff) 1",
            }}
          >
            <Fade direction="left" cascade>
              <div className="ct-pointer">
                <img
                  src={logo}
                  alt="Casttree Logo"
                  width={120}
                  className="pl-5"
                />
              </div>
            </Fade>
            <img
              src={expert.image}
              style={{ width: 200, borderRadius: 12 }}
              className="mt-5"
            />
            <h1 className="ct-cirka-size-48 text-white">{expert.name}</h1>
            <p className="ct-text-size-med-24 text-white">{expert.role}</p>
            <div className="d-flex" style={{ gap: 10 }}>
              <div className="d-flex px-1 expert-rating">
                <img
                  src={star5}
                  style={{ width: 18, height: 18 }}
                  className=""
                />
                <p
                  className="ct-text-size-16 text-white"
                  style={{ position: "relative", top: 8 }}
                >
                  {expert.rating}
                </p>
              </div>
              <div
                className="d-flex ps-3 pe-2"
                style={{
                  backgroundColor: "#FFFFFF0F",
                  width: 120,
                  alignItems: "center",
                  borderRadius: 12,
                }}
              >
                <p
                  className="ct-text-size-16 text-white"
                  style={{ position: "relative", top: 8 }}
                >
                  {expert.sessions}
                </p>
              </div>
            </div>
            <div>
              <p className="ct-text-size-14 text-white mt-4">
                {expert.description}
              </p>
            </div>
            <div className="d-flex" style={{ gap: 10 }}>
              <div className="d-flex px-1 expert-skills">
                <p
                  className="ct-text-size-16-med text-white"
                  style={{ position: "relative", top: 8 }}
                >
                  Pitch control
                </p>
              </div>
              <div className="d-flex ps-3 pe-2 expert-skills">
                <p
                  className="ct-text-size-16-med text-white"
                  style={{ position: "relative", top: 8 }}
                >
                  Voice culture
                </p>
              </div>
            </div>
          </div>
          <div
            className="ws-container1 ps-5 py-2 pb-2 d-md-none "
            style={{
              background: "linear-gradient(360deg, #313131 0%, #000000 100%)",
              borderBottom: "2px solid",
              borderImage: "linear-gradient(to bottom, #ff6a00, #d100ff) 1",
              
            }}
          >
            <div
              className="expert-info d-flex d-lg-row col-lg-12 "
              style={{
                alignItems: "center",
                gap: 10,
              
              }}
            >
              <div className="expert1 text-center ">
                <img
                  src={expert.image}
                  style={{ width: 100, borderRadius: 12 }}
                  className="expert-img "
                />
              </div>
              <div className="expert-details py-2 ">
                <div>
                  <h1 className="ct-cirka-size-48 text-white expert-name pt-2">
                    {expert.name}
                  </h1>
                  <p className="ct-text-size-med-24 text-white expert-skill">
                    {expert.role}
                  </p>
                </div>
                <div
                  className="d-flex"
                  style={{ gap: 10, position: "relative", bottom: 10 }}
                >
                  <div className="d-flex px-1 expertcard-rating">
                    <img src={star5} style={{ width: 12, height: 12 }} />
                    <p
                      className="ct-text-size-16 text-white"
                      style={{ fontSize: 12, position: "relative", top: 8 }}
                    >
                      {expert.rating}
                    </p>
                  </div>
                  <div className="d-flex ps-3 pe-2 expertcard-sessions">
                    <p
                      className="ct-text-size-16 text-white"
                      style={{ fontSize: 12, position: "relative", top: 8 }}
                    >
                      {expert.sessions}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ width: "80%", display: "flex", alignItems: "center" }}
            >
              <p
                className="ct-text-size-14 text-white "
                style={{ fontSize: 12 }}
              >
                {expert.description}
              </p>
            </div>
            <p className="ct-text-size-16 text-white" style={{ fontSize: 12 }}>
              Can help you with
            </p>
            <div className="d-flex" style={{ gap: 10 }}>
              {expert.skills.map((skill, skillIndex) => (
                <div key={skillIndex} className="d-flex px-2 expertcard-skills">
                  <p
                    className="ct-text-size-16-med text-white"
                    style={{
                      fontSize: 12,
                      position: "relative",
                      top: 8,
                    }}
                  >
                    {skill}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpertPage;
