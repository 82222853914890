import React, { useState } from "react";

const WorkExperience = ({ workExperienceData = [] }) => {
  const [showMore, setShowMore] = useState(false);

  if (workExperienceData.length === 0) {
    return null; // Don't render anything if there's no work experience data
  }

  return (
    <div>
      <div className="px-2 col pt-3">
        <div className="px-2 text-white w-100 h-100 d-flex align-items-end">
          <div
            className="d-flex flex-column p-3 w-100 justify-content-center align-items-start"
            style={{
              backgroundColor: "#151515",
              borderRadius: 10,
              gap: "10px",
            }}
          >
            <h2 className="ct-text-size-24" style={{ color: "#8A8A8A" }}>
              Work Experience
            </h2>
            {workExperienceData
              .slice(0, showMore ? workExperienceData.length : 2)
              .map((experience, index) => (
                <React.Fragment key={index}>
                  <div
                    className="d-flex flex-row justify-content-start w-100 h-100 pb-3"
                    style={{
                      gap: "20px",
                    }}
                  >
                    <div
                      className="d-flex pt-2 bg-black justify-content-center align-items-center"
                      style={{
                        width: 70,
                        height: 70,
                        borderRadius: 10,
                        border: "1px solid white",
                      }}
                    >
                      <img
                        src={experience?.media[0]?.media_id?.media_url}
                        alt="logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 10,
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-column justify-content-start align-items-start"
                      style={{
                        width: "70%",
                      }}
                    >
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        {experience?.roleTitle}
                      </p>
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {experience?.companyName}
                      </p>
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {`${new Date(
                          experience?.startDate
                        ).toLocaleDateString("en-US", {
                          month: "short",
                          year: "numeric",
                        })} - ${
                          experience?.isCurrentWork
                            ? "Present"
                            : new Date(
                                experience?.endDate
                              ).toLocaleDateString("en-US", {
                                month: "short",
                                year: "numeric",
                              })
                        }`}
                      </p>
                      <p
                        className="ct-text-size-14 mb-0"
                        style={{
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {experience?.roleDescription}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            {workExperienceData.length > 2 && (
              <div className="mt-2 ct-text-size-14 d-flex justify-content-center align-items-center w-100">
                <button
                  onClick={() => setShowMore(!showMore)}
                  style={{
                    backgroundColor: "transparent",
                    color: "#89CFF0",
                    border: "none",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {showMore ? "Show less" : "Show more"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;