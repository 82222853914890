import React, { useState, useEffect, useRef } from "react";
import logo from "../../assets/ct_trans.png";
// import BGart from "../assets/BGart.png"
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import Congratulations from "../../assets/firework2.png";
import playstore from "../../assets/playstore.png";
import appstore from "../../assets/app_store.png";
import QRCode from "../../assets/casttree_apps_QR.png";
// import FooterData from "../footerData";
import WorkshopInfoDetail from "./workshopInfoDetail";
import workshopService from "../../services/workshopServices";
import successGif from "../../assets/successGif.gif";
import { useParams } from "react-router-dom";

const WorkshopLoading = () => {
  const navigate = useNavigate();
  //const { serviceRequestDataID } = useParams();
  const { workShopId, paymentId } = useParams();
  console.log("serviceRequestData", paymentId);

  const [workshopDetail, setWorkshopDetail] = useState(null);
  const intervalRef = useRef(null);

  const getPaymentDetail = async () => {
    const response = await workshopService.getPaymentDetail(paymentId);
    if (response?.document_status === "Completed") {
      setTimeout(() => {
        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current); // Clear the interval if it's running
          intervalRef.current = null; // Reset the ref to null
        }
        navigate(`/workshop/success/${workShopId}`);
      }, 1000);
    } else {
      console.log("Payment is not completed yet");
    }
  };

  useEffect(() => {
    getPaymentDetail(); // Call immediately on mount

    intervalRef.current = setInterval(getPaymentDetail, 3000); // Start polling every 3 seconds

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current); // Clean up interval on unmount
      }
    };
  }, []);

  return (
    <div className="work-shop-background-image" style={{ height: "100vh" }}>
      <div className="container">
        <div className="ct-p- ct-d-flex-justify-center-home">
          <Fade direction="left" cascade>
            <div className="ct-pointer" onClick={() => navigate("/")}>
              <img
                src={logo}
                alt="Casttree Logo"
                width={120}
                className="pl-5"
              />
            </div>
          </Fade>
        </div>
        <div className="mt-5 loading-gif">
          <div className="">
            <div className="text-center">
              {/* <img src={successGif} alt="Success" className="success-gif" width={150} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopLoading;
