import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkshopNavbarComponent from "./wsNavbar";
import WorkshopBannerCard from "./wsBannerCard";
import WorkshopDateTimeCard from "./wsDateTimeCard";
import WorkshopMeetMentorsCard from "./meetMentorsCard";
import WorkshopHighlights from "./wsHighlights";
import WorkshopReview from "./worshopreview";
import WorkShopMentor from "./workShopMentor";
import WorkshopFrequentlyAskedQuestion from "./wsFAQs";
import workshopService from "../../services/workshopServices";
import SEO from "../common/seo";

const WorkshopLanding = () => {
  const { workShopId } = useParams();
  const [workshopDetail, setWorkshopDetail] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getWorkshopData();
  }, [workShopId]);

  const getWorkshopData = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  return (
    <div className="">
      <SEO
        title="🎤 Casttree Singing Workshops | Learn from Top Singing Experts and Playback Singers"
        description="Join Casttree’s exclusive online singing workshops led by top playback singers. Learn vocal techniques, improvisation, and performance skills in live, interactive sessions. Perfect for aspiring singers worldwide! 🎶✨"
      />
      <WorkshopNavbarComponent />
      <WorkshopBannerCard workshopDetail={workshopDetail} />
      <div style={{ paddingTop: 50 }} id="highlights">
        <WorkshopDateTimeCard workshopDetail={workshopDetail} />
      </div>
      <WorkshopMeetMentorsCard workshopDetail={workshopDetail} />
      <WorkshopHighlights workshopDetail={workshopDetail} />
      <WorkshopReview workshopDetail={workshopDetail} />
      <div id="mentor">
        <WorkShopMentor workshopDetail={workshopDetail} />
      </div>
      <div id="faqs">
        <WorkshopFrequentlyAskedQuestion workshopDetail={workshopDetail} />
      </div>
    </div>
  );
};

export default WorkshopLanding;
