import React, { useEffect, useState } from "react";
import ProfileHeader from "./profileHeader";
import WorkExperience from "./workExperience";
import Education from "./education";
import Awards from "./awards";
import SocialMedia from "./socialMedia";
import UserService from "../../services/userService";
import { useParams } from "react-router-dom";
import logo from "../../assets/ct_trans.png";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

const ProfileDetailScreen = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [profileDetailData, setProfileDetailData] = useState(null);

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        console.log("Fetching profile details for:", username);
        const response = await UserService.getProfileDetail(username);
        const transformedEducation = response?.education?.map((edu) => ({
          institution: edu?.school || "Unknown Institution",
          degree: edu?.degree || {}, // Ensure it supports objects
          duration: edu?.duration || "No Duration Provided",
          logo: edu?.logo || null,
        }));

        setProfileDetailData({
          ...response,
          education: transformedEducation,
        });

        console.log("Profile details fetched successfully:", response);
      } catch (error) {
        console.error("Error fetching profile details:", error);
      }
    };

    fetchProfileDetails();
  }, [username]);

  return (
    <div className="row">
      <div
        className="ct-p- ct-d-flex-justify-center-home p-4 bg-black"
        style={{ width: "100%", zIndex: 1000, height: "100%" }}
      >
        <Fade direction="left" cascade>
          <div className="ct-pointer" onClick={() => navigate("/")}>
            <img src={logo} alt="Casttree Logo" width={120} className="pl-5" />
          </div>
        </Fade>
      </div>
      <div className="col-lg-3 col-md-2"></div>
      <div className="col-lg-6 col-md-8 col">
        {profileDetailData ? (
          <>
            <ProfileHeader profileDetailData={profileDetailData} />
            <WorkExperience
              workExperienceData={profileDetailData?.workExperience || []}
            />
            <Education educationData={profileDetailData?.education || []} />
            <Awards awards={profileDetailData?.awards || []} />
            <SocialMedia socialMedia={profileDetailData?.socialMedia || []} />
          </>
        ) : (
          <p>No profile data available.</p>
        )}
      </div>
      <div className="col-lg-3 col-md-2"></div>
    </div>
  );
};

export default ProfileDetailScreen;