import axios from "axios";
import { APP_CONSTANTS, ENV } from "../const/app.const";


const ENV_VARIABLES = APP_CONSTANTS[ENV];


const CASTTREE_SERVICE_BASE_URL = ENV_VARIABLES.CASTTREE_SERVICE_BASE_URL;


const UserService = {
  getProfileDetail: async (username) => {
    // const token = localStorage.getItem("access_token");
    const headers = {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      `${CASTTREE_SERVICE_BASE_URL}/casttree/profile/viewProfile/${username}`,
      { headers }
    );
    return response.data;
  },
};


export default UserService;