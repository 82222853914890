import React, { useRef, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import casttreeAward from "../../assets/casttree_award.png";

const Categories = () => {
  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="container mt-5 landing-page-main"
      id="Awards"
    >
      <div className="ct-top-30">
        <div className="head">
          <div className="head-div mx-auto ct-center-fixed">
            <Fade direction="down" cascade>
              <section className="div-1 home-text-center">
                <h1 className="ct-title">CATEGORIES</h1>
                <p className="ct-para-text">
                  We've got everything covered! Awards for 17 different
                  categories!
                </p>
                <div className="border-Top-animation my-5">
                  <div className="animation-text">
                    <h3 className="ct-sub-banner-title">
                      PARTCIPATE & WIN UPTO 2 LAKHS OF CASH PRIZES, AWARDS AND
                      MUCH MORE
                    </h3>
                  </div>
                </div>
              </section>
            </Fade>
          </div>
          <Fade direction="left">
            <div className="mt-5 categories-one-bg-img">
              <div className="ct-background-transparent">
                <section className="category-content ct-background-transparent">
                  <div
                    className="text-right ct-background-transparent"
                    style={{ textAlign: "right" }}
                  >
                    <div
                      className="ct-background-transparent"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="ct-background-transparent">
                        <img
                          src={casttreeAward}
                          alt="Casttree Award"
                          width={40}
                          className="ct-background-transparent"
                        />
                      </div>
                      <div
                        className="ct-background-transparent"
                        style={{ marginLeft: "20px" }}
                      >
                        <h1
                          className="categories-title text-right ct-background-transparent"
                          style={{ textAlign: "right" }}
                        >
                          SHORTFILMS AWARDS
                        </h1>
                      </div>
                    </div>
                    <div
                      className="ct-background-transparent"
                      style={{ position: "relative", bottom: "50px" }}
                    >
                      <p className="ct-category-sub-text mt-4 ct-background-transparent">
                        <span
                          className="ct-background-transparent"
                          style={{ color: "rgba(235, 208, 67, 1)" }}
                        >
                          {" "}
                          ✦{" "}
                        </span>{" "}
                        Casttree Gold Film -{" "}
                        <span className="text-gold">₹40,000 prize</span>
                      </p>
                      <p className="ct-category-sub-text mt-4 ct-background-transparent">
                        <span
                          className="ct-background-transparent"
                          style={{ color: "rgba(235, 208, 67, 1)" }}
                        >
                          {" "}
                          ✦{" "}
                        </span>{" "}
                        Casttree Silver Film -{" "}
                        <span className="text-gold">₹20,000 prize</span>
                      </p>
                      <p className="ct-category-sub-text mt-4 ct-background-transparent">
                        <span
                          className="ct-background-transparent"
                          style={{ color: "rgba(235, 208, 67, 1)" }}
                        >
                          {" "}
                          ✦{" "}
                        </span>{" "}
                        Best Social Awareness Shortfilm -{" "}
                        <span className="text-gold">₹5,000 prize</span>
                      </p>
                      <p className="ct-category-sub-text mt-4 ct-background-transparent">
                        <span
                          className="ct-background-transparent"
                          style={{ color: "rgba(235, 208, 67, 1)" }}
                        >
                          {" "}
                          ✦{" "}
                        </span>{" "}
                        Best Documentary Shortfilm -{" "}
                        <span className="text-gold">₹5,000 prize </span>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Fade>

          <div className="mt-5 categories-two-bg-img">
            <Fade direction="down">
              <div className="ct-background-transparent">
                <section className="category-content ct-background-transparent">
                  <div
                    className="text-right ct-background-transparent"
                    style={{ textAlign: "right" }}
                  >
                    <div
                      className="ct-background-transparent"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="ct-background-transparent">
                        <img
                          src={casttreeAward}
                          alt="Casttree Award"
                          width={40}
                          className="ct-background-transparent"
                        />
                      </div>
                      <div
                        className="ct-background-transparent"
                        style={{ marginLeft: "20px" }}
                      >
                        <h1
                          className="categories-title text-right ct-background-transparent"
                          style={{ textAlign: "right" }}
                        >
                          MUSIC & DANCE AWARDS
                        </h1>
                      </div>
                    </div>
                    <div
                      className="ct-background-transparent"
                      style={{ position: "relative", bottom: "50px" }}
                    >
                      <p className="ct-category-sub-text mt-4 text-right ct-background-transparent">
                        <span
                          className="ct-background-transparent"
                          style={{ color: "rgba(235, 208, 67, 1)" }}
                        >
                          {" "}
                          ✦{" "}
                        </span>{" "}
                        Best Independent Album -{" "}
                        <span className="text-gold">₹5,000 prize </span>
                      </p>
                      <p className="ct-category-sub-text mt-4 ct-background-transparent">
                        <span
                          className="ct-background-transparent"
                          style={{ color: "rgba(235, 208, 67, 1)" }}
                        >
                          {" "}
                          ✦{" "}
                        </span>{" "}
                        Best Cover Song -{" "}
                        <span className="text-gold">₹5,000 prize </span>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </Fade>
          </div>

          <div className="mt-5 categories-three-bg-img">
            <div className="ct-background-transparent">
              <Fade direction="right">
                <section className="category-content-three ct-background-transparent">
                  <div className="ct-background-transparent">
                    <div
                      className="ct-background-transparent"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="ct-background-transparent">
                        <img
                          src={casttreeAward}
                          alt="Casttree Award"
                          width={40}
                          className="ct-background-transparent"
                        />
                      </div>
                      <div
                        className="ct-background-transparent"
                        style={{
                          background: "transparent",
                          marginLeft: "20px",
                        }}
                      >
                        <h1
                          className="categories-title text-right ct-background-transparent"
                          style={{
                            background: "transparent",
                            textAlign: "right",
                          }}
                        >
                          INDIVIDUAL AWARDS
                        </h1>
                        <p
                          className="ct-category-sub-Amount text-right ct-background-transparent"
                          style={{
                            background: "transparent",
                            textAlign: "right",
                          }}
                        >
                          <span className="text-gold"> ₹5,000 prize </span> -
                          For each award
                        </p>
                      </div>
                    </div>
                    <div
                      className="ct-background-transparent"
                      style={{ display: "flex", textAlign: "left" }}
                    >
                      <div className="text-left ct-background-transparent">
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Editor
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Musician
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Singer
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Lyricist
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Dancer
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Director
                        </p>
                      </div>

                      <div className="text-left ct-padding-left-30 ct-background-transparent">
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Performance Male
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Performance Female
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Script Writer
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Cinematographer
                        </p>
                        <p className="ct-category-sub-text mt-4 text-left ct-background-transparent">
                          <span
                            className="ct-background-transparent"
                            style={{ color: "rgba(235, 208, 67, 1)" }}
                          >
                            {" "}
                            ✦{" "}
                          </span>{" "}
                          Best Choreographer
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
