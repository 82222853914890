import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import star from "../../assets/Star.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import workshopService from "../../services/workshopServices";

const WorkshopFrequentlyAskedQuestion = ({ workshopDetail }) => {
  const { workShopId } = useParams();
  const [visible, setVisible] = useState([false, false, false, false, false]);

  const toggleVisibility = (index) => {
    const newVisible = [...visible];
    newVisible[index] = !newVisible[index];
    setVisible(newVisible);
  };

  const renderFAQItems = () => {
    return workshopDetail?.itemId?.additionalDetail?.faq?.map((item, index) => (
      <div
        key={index}
        className="text-white mt-3"
        style={{
          marginBottom: "20px",
          cursor: "pointer",
          color: "white",
        }}
        onClick={() => toggleVisibility(index)}
      >
        <div className="d-flex justify-content-between align-items-center">
          <p className="ct-Text-semi-24 mb-0" style={{ color: "white" }}>
            {item.question}
          </p>
          <span className="ct-text-size-20" style={{ color: "white" }}>
            {visible[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </span>
        </div>
        <div
          style={{
            maxHeight: visible[index] ? "1000px" : "0",
            overflow: "hidden",
            transition: "max-height 0.3s ease",
            color: "white",
          }}
        >
          <p className="ct-text-regular-16 mt-2 mb-0" style={{ color: "white" }}>
            {item.answer}
          </p>
        </div>
      </div>
    ));
  };

  return (
    <div className="mt-3 container p-4 p-sm-2 mb-5" style={{ height: "auto" }}>
      <div
        className="d-flex align-items-center justify-content-center mb-4"
        style={{ gap: 10 }}
      >
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
        <p
          className="ct-text-size-24 text-white mb-0"
          style={{
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          Frequently asked questions
        </p>
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
      </div>
      <div className="d-flex justify-content-center">
        <div className="w-100 Ct-qa-items">
          {renderFAQItems()}
        </div>
      </div>
    </div>
  );
};

export default WorkshopFrequentlyAskedQuestion;