import React from "react";
import logoImage from "../../assets/casttree_logo.png";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import Fade from "react-awesome-reveal";

const CSAEPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        className="header-nav navbar-light event-header container"
        fixed="top"
        collapseOnSelect
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          padding: 10,
          position: "relative",
        }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5 ct-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5 pt-5"
            />
          </Navbar.Brand>
        </Fade>
      </Navbar>
      <div className="landing-page-main">
        <div className="ct-p-5 ct-d-flex-justify-center-home">
          <div className="">
            <div className="" style={{ textAlign: "center", marginBottom: 50 }}>
              <h1 className="ct-cirka-size-50" style={{ color: "#FFF" }}>
                Child Sexual Abuse and Exploitation (CSAE)
              </h1>
            </div>
            <div className="">
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Understanding CSAE
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Child Sexual Abuse and Exploitation (CSAE) refers to any form of
                sexual activity involving a child, often through manipulation,
                coercion, or exploitation. This includes physical acts, online
                abuse, and the production or sharing of exploitative materials.
                CSAE is a grave violation of children's rights and has long-term
                psychological, emotional, and physical consequences.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Recognizing the Signs
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Children who are victims of CSAE may exhibit:
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Sudden changes in behavior or mood
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Reluctance to be around certain individuals
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Unexplained injuries or frequent illnesses
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Excessive knowledge of sexual topics for their age
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Withdrawal from social activities or friends
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                It is crucial to approach the situation sensitively if you
                suspect abuse and involve professionals immediately.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                How You Can Help
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                <span className="ct-text-weight-600 text-light">
                  1. Educate Yourself and Others:
                </span>{" "}
                Learn about the indicators of abuse and share this knowledge to
                create awareness.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                <span className="ct-text-weight-600 text-light">
                  2. Open Conversations:
                </span>{" "}
                Foster a safe environment where children feel comfortable
                discussing their concerns.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                <span className="ct-text-weight-600 text-light">
                  3. Report Suspicious Activities:
                </span>{" "}
                If you suspect abuse or exploitation, report it to local
                authorities or child protection agencies.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Creating a Safe Digital Environment
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                In the event that you request cancellation after-hours,
                providing the request came in before you were billed we will
                honour the timestamp on the request and refund you.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                With the increasing prevalence of online abuse, safeguarding
                children in digital spaces is vital. Key steps include:
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Encouraging children to avoid sharing personal information
                online.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                -Using parental controls and monitoring tools to restrict
                inappropriate content.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                - Teaching children to report any uncomfortable or suspicious
                interactions.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Monthly subscriptions are ineligible for refunds, except in
                cases of double billing or other clerical errors. Refund will be
                credited in 5-7 working days to your account.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Our Commitment
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                At Billion Faces Technologies Private Limited, we are dedicated
                to creating a safer world for children. Through education,
                advocacy, and partnerships, we aim to prevent CSAE, support
                survivors, and hold offenders accountable.
              </p>
              <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                Need Help or Want to Get Involved?
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                If you or someone you know is affected by CSAE, reach out for
                help. Together, we can protect children and empower them to
                thrive in a safe, supportive environment. Reach out to us at
                <a
                  href="mailto:contact@casttree.com"
                  style={{ color: "#ee36ff" }}
                >
                {" "}  contact@casttree.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CSAEPolicy;
