import React from "react";
import logoImage from "../../assets/casttree_logo.png";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import Fade from "react-awesome-reveal";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        className="header-nav navbar-light event-header container"
        fixed="top"
        collapseOnSelect
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          padding: 10,
          position: "relative",
        }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5 ct-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5 pt-5"
            />
          </Navbar.Brand>
        </Fade>
      </Navbar>
      <div className="landing-page-main">
        <div className="ct-p-5 ct-d-flex-justify-center-home">
          <div className="">
            <div className="" style={{ textAlign: "center", marginBottom: 50 }}>
              <h1 className="ct-cirka-size-50" style={{ color: "#FFF" }}>
                Privacy Policy
              </h1>
            </div>
            <div className="">
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Casttree and Mobile
                application is a product of BILLION FACES TECHNOLOGIES PRIVATE
                LIMITED registered as per the provisions of the Ministry of
                corporate affairs, [Pursuant to sub-section (2) of section 7 of
                the Companies Act, 2013 and rule 8 of the Companies
                (Incorporation) Rules, 2014], (“the Company”) is committed to
                respecting the privacy of every person who shares information
                with us ("Personal Information") through website{" "}
                <a href="https://casttree.com/" style={{ color: "#ee36ff" }}>
                  {" "}
                  Casttree{" "}
                </a>{" "}
                and mobile application (“the Platform”). Your privacy is
                important to us and we strive to take reasonable care in the
                protection of the information we receive from you.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                The purpose of this Privacy Policy ("Policy"), as amended from
                time to time, is to give you an understanding on how we intend
                to collect, store, transfer and use the information you provide
                to us via the Platform. The Policy may be subject to further
                changes. Upon updating the Policy, we may revise the updated
                date at the bottom of this Policy. We suggest that you regularly
                check this Policy available on the Platform to apprise yourself
                of any updates. Your continued engagement with us will imply
                your acceptance of such updates to this Policy. We believe it is
                important you know how we treat the information about you we
                receive via the Internet.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Information security is critical to our Platform. We work to
                protect the security of your Sensitive Personal Information
                during transmission by using appropriate software, which
                encrypts any data you input. We store information gathered on
                secure computers. The number of employees involved in the
                management of the data centre that have physical access to these
                computers is limited. We use advanced security technology to
                prevent our computers from being accessed by unauthorized
                persons. We have also implemented information security practices
                and standards and have in place comprehensively documented
                information security programs and policies containing
                managerial, technical, operational and physical security
                measures that are in compliance with the regulations under
                Indian laws including but not limited to 'The Information
                Technology Act (Amendment) 2023' and the corresponding rules so
                as to protect the Sensitive Personal Information provided to us
                from unauthorized access, use, modification, damage, disclosure
                or impairment, which is not in accordance with the terms of our
                Privacy Policy and User Terms.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                By using this site, you signify your acceptance of this policy.
                If you do not agree to this policy, please do not use our site.
                Your continued use of the site following the posting of changes
                to this policy will be deemed your acceptance of those changes.
              </p>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  1. SENSITIVE PERSONAL INFORMATION
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  The kind of Personal Information that we collect may include
                  your age, gender, mobile number, email address and any other
                  descriptive information that you may provide about yourself.
                  We may collect, disclose, store, process and transfer your
                  Personal Information to: (i) enable the functioning and
                  services provided by us through the Platform; (ii) provide you
                  with related benefits and services and to engage in / carry
                  out the activities that would enable and assist in providing
                  you the benefits and any services arising out you accessing
                  and using the Platform; (iii) transfer information about you
                  if we are acquired by or merged with another company; (iv)
                  administer or otherwise carry out our obligations, including
                  our obligations under the Terms of Use of the Platform; (v)
                  respond to claims, court orders, or legal process, or to
                  establish or exercise our legal rights or defend against legal
                  claims; (vi) to investigate, prevent, or take action regarding
                  illegal activities, suspected fraud, violations of the
                  Platform or as otherwise required by law; (vii) respond to
                  your requests for customer service; (viii) protect the rights,
                  property, or personal safety of us, our users and the public;
                  (ix) to improve customer service; (x) to personalize user
                  experience; (x) to send periodic emails and sms. Such
                  interception, disclosure, monitoring and storage may take
                  place without your knowledge. In that case, we will not be
                  liable to you or any third party for any damages howsoever
                  arising from such interception, disclosure, monitoring and
                  storage. We may also remove all the personally identifiable
                  information from such Personal Information and use the rest of
                  the data / information enlisted above for historical or
                  statistical purposes or digital structuring purposes. You
                  hereby consent that the collection disclosure, storage,
                  processing and transfer of any Personal Information or any
                  other information as disclosed under this Policy shall not
                  cause any loss or wrongful gain to you if the same is used for
                  the purposes stated in this Policy. Users can always refuse to
                  supply personally identification information, except that it
                  may prevent them from engaging in certain website related
                  activities. Any data that is stored on{" "}
                  <a href="https://casttree.com/" style={{ color: "#ee36ff" }}>
                    {" "}
                    Casttree{" "}
                  </a>{" "}
                  server is treated as proprietary and confidential and is not
                  available to the public.{" "}
                  <a href="https://casttree.com/" style={{ color: "#ee36ff" }}>
                    {" "}
                    Casttree{" "}
                  </a>{" "}
                  has a strict internal security policy with respect to the
                  confidentiality of customer and other data, limiting access
                  only to those employees who have a need to know such
                  information for the purpose of effectively delivering{" "}
                  <a href="https://casttree.com/" style={{ color: "#ee36ff" }}>
                    {" "}
                    Casttree{" "}
                  </a>{" "}
                  products and services.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  2. NON-PERSONAL IDENTIFICATION INFORMATION
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  We may collect non-personal identification information about
                  Users whenever they interact with our Site. Non-personal
                  identification information may include the browser name, the
                  type of computer and technical information about Users means
                  of connection to our Site, such as the operating system and
                  the Internet service providers’ utilized and other similar
                  information.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  3. SECURITY AND CONFIDENTIALITY
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  3.1.This Privacy Policy is published in compliance of Section
                  43A of the Information Technology Act, 2000; and Regulation 4
                  of the Information Technology (Reasonable Security Practices
                  and Procedures and Sensitive Personal Information) Rules, 2011
                  (“SPI Rules”) and Regulation 3(1) of the Intermediary Rules,
                  2011 (“IR Rules”)
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  3.2.Contact information from the registration forms is used to
                  create a unique record to identify the user to the system and
                  for sending information to the member, but is not used for any
                  other purpose. We never sell or rent our users’ information.
                  Demographic and profile data is also collected at our site.
                  This is used for your profiles and is made available to talent
                  buying members for the purpose of contacting you in the event
                  that they would like to make an inquiry. We adopt appropriate
                  data collection, storage and processing practices and security
                  measures to protect against unauthorized access, alteration,
                  disclosure or destruction of your personal information,
                  username, password, transaction information and data stored on
                  our website. The User has full access and authority to control
                  and manage his User profile settings and has the option to
                  allow or restrict Client User/s to view their Personal
                  Information.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  3.3.We will retain your information, including Sensitive
                  Personal Information, for as long as your account is active or
                  as needed to provide you Services. We will remove any
                  information from our database that you request to be removed.
                  However, Sensitive Personal Information and any other
                  information may remain in our or any third party servers as
                  part of standard back-up procedures in accordance with
                  applicable laws.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  3.4.If you wish to cancel your account or request that we no
                  longer use your Sensitive Personal Information to provide you
                  Services, contact us at{" "}
                  <a
                    href="mailto:contact@casttree.com"
                    style={{ color: "#ee36ff" }}
                  >
                    contact@casttree.com
                  </a>
                  . We will retain and use your information including Sensitive
                  Personal Information as necessary to comply with our legal
                  obligations, resolve disputes, and enforce our agreements in
                  accordance with this Privacy Policy.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  3.5.Our web servers collect the domain names, not the e-mail
                  addresses, of visitors. This information may be aggregated on
                  a “no names” basis to measure the number of visits, average
                  time spent on the site, pages viewed, etc.{" "}
                  <a href="https://casttree.com/" style={{ color: "#ee36ff" }}>
                    {" "}
                    Casttree{" "}
                  </a>{" "}
                  uses this information to measure the use of our site and to
                  improve the content of our site.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  4. DISCLAIMER
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  4.1.You understand that the Site may include security
                  components that permit digital materials to be protected, and
                  use of these materials is subject to usage rules set by us
                  and/or content providers who provide content to the Site. You
                  may not attempt to override or circumvent any of the usage
                  rules embedded into the Site.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  4.2.By providing us your information, you hereby consent to
                  the collection, storage, disclosure, processing and transfer
                  of Personal Information for the purposes as disclosed in this
                  Policy. You are providing the Personal Information out of your
                  free will. You have the option not to provide us the data or
                  Personal Information sought to be collected if you do not
                  agree with this Policy. Further, you will have the option to
                  not provide your consent, or withdraw any consent given
                  earlier, provided that the decision to not provide consent /
                  withdrawal of the consent is communicated to us at
                  <a
                    href="mailto:contact@casttree.com"
                    style={{ color: "#ee36ff" }}
                  >
                    contact@casttree.com
                  </a>{" "}
                  . If you do not provide us Personal Information or withdraw
                  the consent to provide us with any of your Personal
                  Information at any point in time, we shall have the option not
                  to provide the benefits for the purpose of which the said
                  Personal Information was sought.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  4.3.You can choose to opt out of receiving any further email
                  or sms correspondence from us or our Affiliates. We will not
                  sell, rent, or trade your email address to any third-party,
                  other than our Affiliates, without your prior permission. You
                  understand that email is not considered a secure form of
                  communication. Generally, any information you disclose in an
                  email may be viewed by hackers while such information is on
                  route to us. As such, ensure that you take due care in what
                  you disclose in any email that you send to us.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  4.4.When you register with the Website and subsequently use
                  the Website, you will receive communications from the Company
                  (or from persons on behalf of the Company such as call centres
                  and data processors), including but not limited by way of
                  e-mail, sms, mms or over the telephone. Some communications
                  may be promotional and specific in nature, such as for
                  example, a display of information of an offer you may be
                  interested in, or a service offering provided by one of the
                  Company's partners or affiliates. You shall continue to
                  receive such communication while you use the Website. You may
                  contact the Company by sending an email at
                  <a
                    href="mailto:contact@casttree.com"
                    style={{ color: "#ee36ff" }}
                  >
                    contact@casttree.com
                  </a>
                  , by including your name, address, and email address, along
                  with details about any specific communications you do not wish
                  to receive from the Company.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  4.5.By using the Website, you agree to receive the information
                  as above even if you are registered with Do Not Disturb (DND)
                  registry. You agree and acknowledge that such communication
                  shall not be subject to further permissions from you. You
                  agree that any communication sent to you by the Company (or
                  any person on behalf of the Company) in relation to the
                  Website or any prospective offers shall not be unsolicited
                  commercial communication and that the communication shall
                  deemed to have been sent to you at your specific request.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  5. CHILD PARENTAL CONTROL
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  Protecting the privacy of children is important to us. We will
                  never knowingly collect any personal information about
                  children under the age of 18. If you are under the age of 18,
                  you are not allowed to become a registered member of the
                  Website unless a parent or legal guardian who agrees to be
                  bound by our Privacy Policy and Terms of Use registers you on
                  your behalf. It is important to remember that we cannot always
                  determine that a member is a child under the age of 18.
                  Members under the age of 18 must have written parental consent
                  to release their name or other personal information.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  6. USER WARRANTS
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  6.1.You authorize us to transfer, share, part with your
                  Personal Information, across borders and from your country and
                  jurisdiction to any other countries and jurisdictions across
                  the world (including India), with our affiliates / agents /
                  third party service providers / partners and other agencies
                  for purposes specified under this Policy or as may be required
                  by law. If you need to access your Personal Information,
                  update or correct your Personal Information for any reason,
                  you may do so on your profile review page on the Platform, and
                  your Personal Information will accordingly be updated /
                  corrected on a real-time basis. In the event you wish to know
                  the names and addresses of the third parties your Personal
                  Information has been disclosed to, you may request us for such
                  information by communicating to us at{" "}
                  <a
                    href="mailto:contact@casttree.com"
                    style={{ color: "#ee36ff" }}
                  >
                    contact@casttree.com
                  </a>
                  . We will make all reasonable efforts to revert to you within
                  a reasonable period of time, with the information that you
                  require.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  6.2.com accordance with Regulation 3(2) of the IR Rules, you
                  shall not host, display, upload, modify, publish, transmit,
                  update or share any information that: (i) belongs to another
                  person and to which the user does not have any right to; (ii)
                  is grossly harmful, harassing, blasphemous defamatory,
                  obscene, pornographic, paedophilic, libellous, invasive of
                  another's privacy, hateful, or racially, ethnically
                  objectionable, disparaging, relating or encouraging money
                  laundering or gambling, or otherwise unlawful in any manner
                  whatever; (iii) harm minors in any way; (iv) infringes any
                  patent, trademark, copyright or other proprietary rights; (v)
                  violates any law for the time being in force; (vi) deceives or
                  misleads the addressee about the origin of such messages or
                  communicates any information which is grossly offensive or
                  menacing in nature; (vii) impersonate another person; (viii)
                  contains software viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer resource; (ix) threatens the
                  unity, integrity, defence, security or sovereignty of India,
                  friendly relations with foreign states, or public order or
                  causes incitement to the commission of any cognisable offence
                  or prevents investigation of any offence or is insulting any
                  other nation
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  7. AMENDMENTS TO PRIVACY POLICY
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  The Company reserves the right to change, modify, add, or
                  remove portions of this Privacy Policy at any time to increase
                  our members’ privacy protection or to improve our information
                  practices of for any reason. Such changes shall be effective
                  immediately upon posting of the latest Privacy Policy on the
                  Website and/or the App. We suggest that you review the updated
                  Privacy Policy periodically for changes. The latest version of
                  our Privacy Policy can be accessed from a link on our Website
                  and/or App. You acknowledge that by accessing our Website
                  and/or App after we have posted changes to this Privacy
                  Policy, you are agreeing to the terms of the Privacy Policy as
                  on the date of such use. If there are changes made in the
                  future, the changes will generally be prospective in nature
                  and may not have any retrospective effect on the information
                  already collected. Your continued membership will signify your
                  unconditional acceptance to the modified terms.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  8. COOKIES
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  8.1 Our Website and App may use “cookies” so that we can
                  provide you with more customized information when you use our
                  Services. “Cookies” are used to store user preferences and to
                  track user trends, so as to enhance your interactive
                  experience and generally improve our Services to you. You can
                  set your browser to notify you when you are sent a “cookie”,
                  giving you the chance to decide whether or not to accept it.
                  If you do accept a “cookie”, you thereby agree to our use of
                  any Sensitive Personal Information collected by us using that
                  Cookie.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  8.2 A cookie is a small file containing a string of characters
                  that uniquely identifies your browser. The Website uses such
                  cookies automatically to improve the quality of the Website by
                  storing non-personally identifiable information, such as but
                  not limited to user preferences and tracking user trends. Most
                  internet surfing browsers are initially set up to accept
                  cookies, but you can reset your browser to refuse all cookies
                  or to indicate when a cookie is being requested. When you use
                  the Website, the Company may collate non-personally
                  identifiable information about your use of the Website. The
                  Company uses this data for analyzing and enhancing the Website
                  and sometimes the Company may disclose it in aggregate form to
                  the Company's partners. This may be used by them directly or
                  indirectly by them for providing other services as well.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  8.3 The Company may also employ pixel tags, GA codes or other
                  industry standard technologies in the Website, or through
                  emails or other services provided by the Company's partners.
                  These technologies use small graphic images placed on websites
                  or emails, and they enable the Company to understand whether
                  you have performed a specific action on the Website, or to
                  measure the performance of the Company's services, or to
                  understand usage patterns and trends.
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  8.4 Content, information about your correspondents, and the
                  destination/origin of communications between you and any other
                  person using our Services, which include email communications,
                  blog, chat room and discussion board communications, instant
                  message communications, experts forum communications,
                  facsimile mail communications, membership of mailing lists
                  etc. (“Correspondence Information”)
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  9. LINKS TO THIRD PARTIES
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  The Company may provide links to various third parties
                  including but not limited to YouTube, Sound Cloud, Daily
                  Motion, and Vimeo on its Platform for the functionality of its
                  Services. You accept and acknowledge that if such third party
                  link directs you to an external website, the Company is not
                  responsible for any or all content on such third party
                  website. If you decide to visit any such external link, you
                  agree to do so at your own risk, responsibility and liability.
                  The Company makes no warranties, representations or
                  endorsements relating to the content, information, products or
                  services as may be appearing on these websites. The Company
                  does not endorse any service provider listed on its Website or
                  Application. The Company may promote or provide service qua
                  social media through necessary integration. For this purpose,
                  the User may opt to log in to the social media by creating an
                  account or signing in on such social media. If the User elects
                  to avail the Services via social media, the terms of use and
                  privacy policy of that social media will be applicable and the
                  Company will not be responsible for any kind of transmission
                  or publication of any contents on any social media.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  10. TESTIMONIALS
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  Where the User posts any review, submission, feedback or
                  comment or any statement of such nature on the related link on
                  its User Profile, such content shall be posted publicly on the
                  Website on the sole discretion of the Company and shall not be
                  subject to any alteration or modification or moderation by the
                  Company or its members and the User has no discretion or
                  authority or right to compel the Company for publication of
                  such content so posted by him on his User profile.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  11. CONTACT
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  If you have any questions about this Privacy Policy, the
                  practices and/or dealings or functionality of the Platform you
                  may get in touch with us at{" "}
                  <a
                    href="mailto:contact@casttree.com"
                    style={{ color: "#ee36ff" }}
                  >
                    contact@casttree.com
                  </a>
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Severability
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  All rights and restrictions contained in this Agreement may be
                  exercised and shall be applicable and binding only to the
                  extent that they do not violate any applicable laws and are
                  intended to be limited to the extent necessary so that they
                  will not render this Agreement illegal, invalid or
                  unenforceable. If any provision or portion of any provision of
                  this Agreement shall be held to be illegal, invalid or
                  unenforceable by a court of competent jurisdiction, it is the
                  intention of the parties that the remaining provisions or
                  portions thereof shall constitute their agreement with respect
                  to the subject matter hereof, and all such remaining
                  provisions or portions thereof shall remain in full force and
                  effect.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  12. GRIEVANCE OFFICER
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  In compliance with The Information Technology Act (Amendment)
                  2023 and the rules made thereunder, the Grievance Officer of
                  Casttree for the purpose of
                  this Agreement shall be: Karthik Gunasekaran.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
