import React from 'react';
import { Fade } from "react-awesome-reveal";
import Past1 from "../../assets/past_1.png";
import Past2 from "../../assets/past_2.png";
import Past3 from "../../assets/past_3.png";
import Past4 from "../../assets/past_4.png";
import Past5 from "../../assets/past_5.png";
import Past6 from "../../assets/past_6.png";
import Past7 from "../../assets/past_7.png";
import Past8 from "../../assets/past_8.png";
import Past9 from "../../assets/past_9.png";
import Past10 from "../../assets/past_10.png";
import Past11 from "../../assets/past_11.png";
import Past12 from "../../assets/past_12.png";
import Past13 from "../../assets/past_13.png";
import Past14 from "../../assets/past_14.png";
import FlashBackImageLR from "./flashBackImageLR";
import FlashBackImageRL from "./flashBackImageRL"
import FlashBackImageFinal from './flashBackImageFinal';

const imageslr = [
    { image: Past5, },
    { image: Past6,},
    { image: Past7,},
    { image: Past8,},
    { image: Past9,},
    { image: Past10, },
    { image: Past11},
    { image: Past12},
];

const images = [
    { image: Past13},
    { image: Past14},
    { image: Past1,},
    { image: Past2, },
    { image: Past3, },
    { image: Past4,},
    { image: Past5,},
    { image: Past12,},
];

const imagesfinal = [
    { image: Past7,},
    { image: Past8,},
    { image: Past9, },
    { image: Past10, },
    { image: Past11,},
    { image: Past12},
    { image: Past2},
    { image: Past6,},
];

const FlashBack = () => {

    return (
        <div
            style={{ background: "#000", color: "white" }}
            className="container mt-5 landing-page-main"
            id="Flashback"
        >
            <div className='ct-top-30'>
                <div className="head">
                    <div className="head-div mx-auto ct-center-fixed">
                        <Fade direction='down' cascade>
                            <section className="div-1 home-text-center">
                                <h1 className="ct-title">NOW SOME FLASHBACKS</h1>
                                <p className="ct-para-text">
                                    This is not our first time. And definitely not the last.
                                </p>
                            </section>
                        </Fade>
                    </div>
                    <div className='mt-4'>
                        <FlashBackImageLR images={images} scrollSpeed={40} />
                    </div>
                    <div className='mt-4'>
                        <FlashBackImageRL images={imageslr} scrollSpeed={50} />
                    </div>
                    <div className='mt-4'>
                        <FlashBackImageFinal images={imagesfinal} scrollSpeed={30} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlashBack;
