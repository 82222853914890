import React, { useState, useEffect } from "react";
import logo from "../../assets/ct_trans.png";
import help from "../../assets/help.jpg";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import useResendTimer from "../common/useResendTimer";
import AuthService from "../../services/authService";
import toast, { Toaster } from "react-hot-toast";
import authService from "../../services/authService";
import FooterData from "../workshop/footerData";
import WorkshopInfoDetail from "./workshopInfoDetail";
import { useParams } from "react-router-dom";
import workshopService from "../../services/workshopServices";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HelpIcon from "@mui/icons-material/Help";
import SEO from "../common/seo";
import LoginScreen from "../authService/loginScreen";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const WorkshopLogin = () => {
  const navigate = useNavigate();
  const [workshopDetail, setWorkshopDetail] = useState(null);
  const { workShopId } = useParams();
  const [communityURL, setCommunityURL] = useState(null);
  const [userData, setUser] = useState("");
  const [workshopParticipantData, setWorkshopParticipantData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCommunityJoin = async () => {
      try {
        const response = await authService.getCommunityJoinChennal();
        setCommunityURL(response);
      } catch (error) {
        console.error("Error fetching community:", error);
      }
    };

    fetchCommunityJoin();
  }, []);

  useEffect(() => {
    getWorkshopDetail();
  }, [workShopId]);

  const getWorkshopDetail = async () => {
    const response = await workshopService.getServiceItemDetail(workShopId);
    setWorkshopDetail(response);
  };

  const handleLoginSuccess = async (otpResponse) => {
    const userData = otpResponse.user;
    const otpResponseData = otpResponse?.userOrganization[0].organizationId;
    const updatedData = getProperUserDetail(otpResponse);
    localStorage.setItem("user_updated_data", JSON.stringify(updatedData));
    localStorage.setItem("access_token", otpResponse.access_token);
    localStorage.setItem("user_detail", JSON.stringify(userData));
    localStorage.setItem(
      "user_otpResponse_data",
      JSON.stringify(otpResponseData)
    );
    localStorage.setItem("user_id", userData._id);
    setUser(userData);

    await workshopParticipant();
  };

  const workshopParticipant = async () => {
    try {
      const response = await workshopService.getPremiumDetail(
        workshopDetail?.itemId?._id
      );
      setWorkshopParticipantData(response);
      if (response?.data?.isApplied) {
        navigate(`/workshop/success/${workShopId}`);
      } else {
        navigate(`/workshop/create/${workShopId}`);
      }
      return response;
    } catch (error) {
      console.log("Error fetching workshop participant data:", error);
    }
  };

  const getProperUserDetail = async (userDatas) => {
    const filteredProfiles = userDatas?.profile?.filter(
      (profile) => profile?.type !== "Expert"
    );
    const profileDetail = filteredProfiles[0];
    const updatedMainObject = {
      ...userDatas,
      profileDetail,
    };
    localStorage.setItem("userProfileData", JSON.stringify(profileDetail));
    return updatedMainObject;
  };

  return (
    <div style={{ width: "100%", height: "110vh" }}>
      <SEO
        title="🎤 Casttree Singing Workshops | Learn from Top Singing Experts and Playback Singers"
        description="Join Casttree’s exclusive online singing workshops led by top playback singers. Learn vocal techniques, improvisation, and performance skills in live, interactive sessions. Perfect for aspiring singers worldwide! 🎶✨"
      />
      <div className="work-shop-background-image" style={{ height: "100%" }}>
        <div className="container">
          <div className="ct-p- ct-d-flex-justify-center-home pt-4">
            <Fade direction="left" cascade>
              <div className="ct-pointer" onClick={() => navigate("/")}>
                <img
                  src={logo}
                  alt="Casttree Logo"
                  width={120}
                  className="pl-5"
                />
              </div>
            </Fade>
            <div
              onClick={() => {
                window.open(communityURL?.link);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
                cursor: "pointer",
              }}
            >
              <WhatsAppIcon
                style={{
                  fill: "white",
                  fontSize: 25,
                  position: "relative",
                  bottom: 8,
                }}
              />
              <p className="ct-text-size-24" style={{ color: "#FFFFFF" }}>
                Help
              </p>
            </div>
          </div>
          <div className="ct-display-flex-mbl-unset pt-4">
            <div className="ws-card-first ct-bg-white ct-margin-20 mbl-mb-20">
              <WorkshopInfoDetail workshopDetail={workshopDetail} />
            </div>
            <div className="ws-card-second ct-bg-white">
              <div className="pb-2">
                <p
                  className="ct-cirka-size-26 mb-1"
                  style={{ colour: "#FFFF" }}
                >
                  Tell us details about you{" "}
                </p>
                <p className="ct-text-size-14-gray">
                  Give us details about you
                </p>
              </div>
              <LoginScreen onLoginSuccess={handleLoginSuccess} />
            </div>
          </div>
        </div>
        <FooterData />
      </div>
    </div>
  );
};

export default WorkshopLogin;
