import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import { styled } from "@mui/system";

const ApplyNow = () => {
  const navigate = useNavigate();
  const ctToken = localStorage.getItem("access_token");
  const [showRulesDialog, setShowRulesDialog] = React.useState(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const handleClose = () => {
    setShowRulesDialog(false);
  };

  const openRulesDialog = () => {
    setShowRulesDialog(true);
  };

  const handleApplyNowClick = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (ctToken) {
      try {
        const response = await authService.getProjectList();
        if (response?.data?.length > 0) {
          navigate(`/event-create?tab=${3}`);
        } else {
          navigate(`/event-create?tab=${2}`);
        }
      } catch (error) {
        console.error("Error fetching project list:", error);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      style={{ background: "#000", color: "white" }}
      className="container mt-5 landing-page-main"
      id="applynow"
    >
      <div className="ct-top-30">
        <Fade direction="down">
          <div className="head">
            <div className="head-div mx-auto ct-center-fixed pb-3">
              <section className="div-1 home-text-center">
                <h1 className="categories-title">What are you waiting for? </h1>
                {/* <p className="ct-para-text">Send the application right now</p> */}
              </section>
            </div>
            <div className="mt-4 home-text-center">
              <div className="ct-display-flex ct-justify-center">
                {/* <div className="ct-marign-right" style={{ marginTop: 3 }}>
                  <a
                    className="my-3 apply-now-fill ct-pointer"
                    onClick={handleApplyNowClick}
                    style={{ cursor: "pointer", color: "#FFF" }}
                  >
                    VIEW SUBMISSION
                  </a>
                </div> */}
                <div
                  className="ct-pointer"
                  style={{ position: "relative", bottom: 10 }}
                  onClick={openRulesDialog}
                >
                  <p className="rules-now-btn mb-0 rules-border ct-pointer">
                    <span className="rules-border-span">SEE EVENT RULES</span>
                  </p>
                </div>
              </div>

              <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={showRulesDialog}
              >
                <p
                  className="ct-text-18 ct-text-weight-600 ct-text-light p-3 pt-3"
                  id="customized-dialog-title"
                >
                  Rules and regulations
                </p>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#FFF",
                  }}
                  className="ct-pointer"
                >
                  <HighlightOffIcon className="ct-text-18" />
                </IconButton>
                <DialogContent dividers>
                  <div className="dialog-custom-rules">
                    <p className="ct-text-14 ct-text-light">
                      <ul>
                        <li className="ct-text-weight-500 ct-text-16">
                          1. Submitting Projects can only be of the following
                          types
                        </li>
                        <div className="ct-pl-20">
                          <li>a. Short Film - Any Genre</li>
                          <li>
                            b. Music Video - Independent song/ Cover Song/ Self
                            Recorded
                          </li>
                          <li>c. Dance Video - Any Style</li>
                        </div>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          2. Duration of the projects should be restricted to
                          the below mentioned durations (max file size: 2 GB)
                        </li>
                        <div className="ct-pl-20">
                          <li>
                            a. Short Film - Max. 25mins (including credits)
                          </li>
                          <li>
                            b. Music Video and Dance Video - Max 9mins
                            (including credits)
                          </li>
                        </div>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          3. All languages are accepted. Projects must be
                          subtitled in English if language is other than English
                          and Tamil.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          4. Year of production of Projects: From January 2020
                          to Till date.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          5. There is no age restriction for participation.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          6. Last date for application submission: September 5th
                          2024.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          7. Each participant can submit any number of entries.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          8. Projects must be original works created by the
                          submitting filmmaker(s), singers or dancers and all
                          necessary permissions and clearances must be obtained
                          for copyrighted material, including music, images, and
                          footage.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          9. Projects should not contain nudidity, partially
                          nude, discriminatory, unlawful, infringing, hateful,
                          extreme violence, pornographic or sexually suggestive
                          photographs, visuals, audio-visuals or propoganda
                          towards any political party, ideology or leaders.
                          Projects violating this rule will be deemed
                          disqualified.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          10. CASTREE 2024 Selection stages/process
                        </li>
                        <div className="ct-pl-20">
                          <li>
                            a. Entry submission as per rules and regulations.
                            Initial scrutiny for entry acceptanece as per rules
                            and regulations.
                          </li>
                          <li>
                            b. Initial Round : First round of evaluation as per
                            evaluation guideline set by the jury panel.
                          </li>
                          <li>
                            c. Final Round : Second round of evaluation by Jury
                            panel as per evaluation guidelines set by jury
                            panel.
                          </li>
                          <li>
                            d. Winner Selection: Final Evaluation by Jury head
                            along with jury panel.
                          </li>
                        </div>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          11. The decision of the JURY panel will be final and
                          cannot be challenged.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          12. CASTTREE and Venue Partner does not take any type
                          financial commitment for the expense incurred on the
                          correspondence by the participant. All Travel, Lodging
                          & Boarding and other miscellaneous expenses will have
                          to be borne by the participant himself for attending
                          the Event. No request for the same will be
                          entertained.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          13. The registered projects can be withdrawed before
                          the deadline for submissions through a mail to the
                          help center. Strictly no refunds will be processed for
                          application withdrawals.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          14. Projects that do not comply with the rules and
                          regulations may be disqualified from consideration. No
                          refund will be processed for disqualification due to
                          violation in eligibility, rules and regulations of the
                          event.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          15. Certification - All the participants will receive
                          a Digital Certificate of Particiaption via Casttre
                          Mobile / Webapp, The winners will be honored with
                          winning certificate and trophy, cash prize.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          16. The organizers reserve the right to amend the
                          rules and regulations of the event's if necessary. Any
                          changes will be communicated to participants through
                          email or Whatsapp and the event's website. By
                          submitting an entry, filmmakers agree to abide by
                          these rules and regulations.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          17. Applications reaching the final evaluation will be
                          screened at the event on September 12th at Vels
                          University.
                        </li>
                        <br />
                        <li className="ct-text-weight-500 ct-text-16">
                          18. For inquiries or clarifications, please email us
                          at contact@casttree.com
                        </li>
                      </ul>
                    </p>
                  </div>
                </DialogContent>
              </BootstrapDialog>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default ApplyNow;
