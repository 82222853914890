import React from "react";
import star5 from "../../assets/star5.png";
import star3 from "../../assets/star3.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ComputerIcon from "@mui/icons-material/Computer";
import TranslateIcon from "@mui/icons-material/Translate";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import quotes from "../../assets/Quotes.png";
import { Tag } from "@mui/icons-material";
import HearFromUs from "./hearFromUs";

const OfferingsPage = () => {
  const originalPrice = 199;
  const discountedPrice = 99;
  const offerings = [
    {
      title: "Singing & Music Workshop 101",
      description:
        "Learn song writing and music composition from the best of the best. Aparna Narayanan will teach how to get into Super Singer",
      date: "30 Oct 2024",
      time: "6:30 - 8 PM",
      language: "Tamil",
      mode: "Online",
      rating: "4.8/5",
      discountedPrice: "99",
      originalPrice: "199",
    },
    {
      title: "Singing & Music Workshop 101",
      description:
        "Learn song writing and music composition from the best of the best. Aparna Narayanan will teach how to get into Super Singer",
      date: "30 Oct 2024",
      time: "6:30 - 8 PM",
      language: "Tamil",
      mode: "Online",
      rating: "4.8/5",
      discountedPrice: "99",
      originalPrice: "199",
    },
    {
      title: "Singing & Music Workshop 101",
      description:
        "Learn song writing and music composition from the best of the best. Aparna Narayanan will teach how to get into Super Singer",
      date: "30 Oct 2024",
      time: "6:30 - 8 PM",
      language: "Tamil",
      mode: "Online",
      rating: "4.8/5",
      discountedPrice: "99",
      originalPrice: "199",
    },
    {
      title: "Singing & Music Workshop 101",
      description:
        "Learn song writing and music composition from the best of the best. Aparna Narayanan will teach how to get into Super Singer",
      date: "30 Oct 2024",
      time: "6:30 - 8 PM",
      language: "Tamil",
      mode: "Online",
      rating: "4.8/5",
      discountedPrice: "99",
      originalPrice: "199",
    },
    {
      title: "Singing & Music Workshop 101",
      description:
        "Learn song writing and music composition from the best of the best. Aparna Narayanan will teach how to get into Super Singer",
      date: "30 Oct 2024",
      time: "6:30 - 8 PM",
      language: "Tamil",
      mode: "Online",
      rating: "4.8/5",
      discountedPrice: "99",
      originalPrice: "199",
    },
  ];
  const feedbackItems = [
    {
      title: "Singing Feedback",
      description:
        "Get personalized feedback for your work from Aparna Narayanan and upskill yourself",
      rating: 4.8,
      respondsIn: "7 days",
      currency: "INR",
      discountedPrice: 799,
      originalPrice: 1199,
    },
    {
      title: "Singing Feedback",
      description:
        "Get personalized feedback for your work from Aparna Narayanan and upskill yourself",
      rating: 4.8,
      respondsIn: "7 days",
      currency: "INR",
      discountedPrice: 799,
      originalPrice: 1199,
    },
    {
      title: "Singing Feedback",
      description:
        "Get personalized feedback for your work from Aparna Narayanan and upskill yourself",
      rating: 4.8,
      respondsIn: "7 days",
      currency: "INR",
      discountedPrice: 799,
      originalPrice: 1199,
    },
    {
      title: "Singing Feedback",
      description:
        "Get personalized feedback for your work from Aparna Narayanan and upskill yourself",
      rating: 4.8,
      respondsIn: "7 days",
      currency: "INR",
      discountedPrice: 799,
      originalPrice: 1199,
    },
    {
      title: "Singing Feedback",
      description:
        "Get personalized feedback for your work from Aparna Narayanan and upskill yourself",
      rating: 4.8,
      respondsIn: "7 days",
      currency: "INR",
      discountedPrice: 799,
      originalPrice: 1199,
    },
  ];

  return (
    <div className="ct-ws-feedback col-12">
      <div className="containers  container2  ct-text-size-24 text-white p-lg-5 p-3  d-md-block">
        <div>
          <div
            className="d-flex col"
            style={{ alignItems: "center", justifyContent: "center", gap: 10 }}
          >
            <img src={star3} style={{ width: 24, height: 24 }} className="" />
            <h1 className="d-flex ct-text-size-24 text-white offering-title">
              Offerings
            </h1>
            <img src={star3} style={{ width: 24, height: 24 }} className="" />
          </div>
          <div className="d-flex mt-5" style={{ gap: 10 }}>
            <div className="d-flex ps-3 pe-3 all-offerings">
              <p
                className="ct-text-size-16 text-black"
                style={{
                  position: "relative",
                  top: 8,
                }}
              >
                All
              </p>
            </div>
            <div className="d-flex px-3 workshop-offering">
              <p
                className="ct-text-size-16 text-white"
                style={{ position: "relative", top: 8 }}
              >
                Workshops
              </p>
            </div>
            <div className="d-flex px-3 workshop-offering">
              <p
                className="ct-text-size-16 text-white"
                style={{ position: "relative", top: 8 }}
              >
                Feedback
              </p>
            </div>
            <div className="d-flex  px-3 workshop-offering">
              <p
                className="ct-text-size-16 text-white"
                style={{ position: "relative", top: 8 }}
              >
                Courses
              </p>
            </div>
          </div>
        </div>

        <div
          className=" mt-4 WorkShopCard "
          style={{
            backgroundColor: "black",
          }}
        >
          <div className="feedbackcard">
            {offerings.map((workshop, index) => (
              <div key={index} className="card-body_1 p-3">
                <div className="d-flex mt-1" style={{ gap: 10 }}>
                  <div className="d-flex px-1 ws-card">
                    <p
                      className="ct-text-size-12"
                      style={{ position: "relative", top: 8 }}
                    >
                      workshops
                    </p>
                  </div>
                  <div className="d-flex px-1 ws-rating">
                    <img
                      src={star5}
                      style={{ width: 15, height: 15 }}
                      className=""
                      alt="star rating"
                    />
                    <p
                      className="ct-text-size-12-med text-white"
                      style={{ position: "relative", top: 8 }}
                    >
                      {workshop.rating}
                    </p>
                  </div>
                  <div
                    className="d-flex ps-3 pe-2"
                    style={{
                      backgroundColor: "#FFFFFF0F",
                      width: 85,
                      alignItems: "center",
                      borderRadius: 50,
                    }}
                  >
                    <p
                      className="ct-text-size-12-med text-white"
                      style={{ position: "relative", top: 8 }}
                    >
                      Top rated
                    </p>
                  </div>
                </div>
                <div>
                  <p className="ct-text-size-16-med text-white mt-2">
                    {workshop.title}
                  </p>
                  <p className="ct-text-size-12-med text-white">
                    {workshop.description}
                  </p>
                </div>
                <div
                  className="col"
                  style={{
                    width: "100%",
                    display: "flex",
                    borderRadius: 10,
                    gap: 10,
                  }}
                >
                  <div className="p-2 px-2 icon-tag">
                    <div className="d-flex p-2 calendar-icon">
                      <CalendarTodayIcon style={{ color: "#FFFFFF" }} />
                    </div>
                    <div className="d-flex row" style={{ gap: 1 }}>
                      <p className="ct-text-size-12-reg mb-0">Date</p>
                      <p className="ct-text-size-14-bold mb-0">
                        {workshop.date}
                      </p>
                    </div>
                  </div>
                  <div className="p-2 px-2 icon-tag">
                    <div className="d-flex p-2 calendar-icon">
                      <AccessTimeIcon style={{ color: "#FFFFFF" }} />
                    </div>
                    <div className="d-flex row" style={{ gap: 1 }}>
                      <p className="ct-text-size-12-reg mb-0">Time</p>
                      <p className="ct-text-size-14-bold mb-0">
                        {workshop.time}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col mt-2"
                  style={{
                    width: "100%",
                    display: "flex",
                    borderRadius: 10,
                    gap: 10,
                  }}
                >
                  <div className="p-2 px-2 icon-tag">
                    <div className="d-flex p-2 calendar-icon">
                      <TranslateIcon style={{ color: "#FFFFFF" }} />
                    </div>
                    <div className="d-flex row" style={{ gap: 1 }}>
                      <p className="ct-text-size-12-reg mb-0">Language</p>
                      <p className="ct-text-size-14-bold mb-0">
                        {workshop.language}
                      </p>
                    </div>
                  </div>
                  <div className="p-2 icon-tag">
                    <div className="d-flex p-2 calendar-icon">
                      <ComputerIcon style={{ color: "#FFFFFF" }} />
                    </div>
                    <div
                      className="d-flex row"
                      style={{ gap: 1, justifyContent: "flex-start" }}
                    >
                      <p className="ct-text-size-12-reg mb-0">Mode</p>
                      <p className="ct-text-size-14-bold mb-0">
                        {workshop.mode}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex col p-2 mt-4 inr-price">
                  <div style={{ width: "50%", gap: 10 }}>
                    <span className="ct-text-size-24-bold text-white col">
                      INR
                    </span>
                    <span className="ct-text-size-24-bold text-white col px-1">
                      {workshop.discountedPrice}
                    </span>
                    <span className="ct-text-size-16-med text-white col ps-2 OriginalPrice">
                      {workshop.originalPrice}
                    </span>
                  </div>
                  <div>
                    <button className="ct-text-size-14-bold register-now">
                      Register Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="feedbackcard">
            {feedbackItems.map((item, index) => (
              <div key={index} className="card-body-1 p-3">
                <div className="d-flex mt-1" style={{ gap: 10 }}>
                  <div className="d-flex px-1 feedback-title">
                    <p
                      className="ct-text-size-12"
                      style={{ position: "relative", top: 8 }}
                    >a
                      Feedbacks
                    </p>
                  </div>
                  <div className="d-flex px-1 ws-rating">
                    <img
                      src={star5}
                      style={{ width: 15, height: 15 }}
                      alt="star rating"
                    />
                    <p
                      className="ct-text-size-12-med text-white"
                      style={{ position: "relative", top: 8 }}
                    >
                      {item.rating}/5
                    </p>
                  </div>
                  <div className="d-flex ps-3 pe-2 ws-rating">
                    <p
                      className="ct-text-size-12-med text-white"
                      style={{ position: "relative", top: 8 }}
                    >
                      Popular
                    </p>
                  </div>
                </div>
                <div>
                  <p className="ct-text-size-16-med text-white mt-2">
                    {item.title}
                  </p>
                  <p className="ct-text-size-12-med text-white">
                    {item.description}
                  </p>
                </div>
                <div
                  className="col"
                  style={{
                    width: "100%",
                    display: "flex",
                    borderRadius: 10,
                    gap: 10,
                  }}
                >
                  <div className="p-2 px-2 icon-tag">
                    <div className="d-flex p-2 calendar-icon">
                      <CalendarTodayIcon style={{ color: "#FFFFFF" }} />
                    </div>
                    <div className="d-flex row" style={{ gap: 1 }}>
                      <p className="ct-text-size-12-reg mb-0">Responds in</p>
                      <p className="ct-text-size-14-bold mb-0">
                        {item.respondsIn}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex col p-2 mt-4"
                  style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "50px",
                  }}
                >
                  <div style={{ width: "50%", gap: 10 }}>
                    <span className="ct-text-size-24-bold text-white col">
                      {item.currency}
                    </span>
                    <span className="ct-text-size-24-bold text-white col px-1">
                      {item.discountedPrice}
                    </span>
                    <span className="ct-text-size-16-med text-white col ps-2 OriginalPrice">
                      {item.originalPrice}
                    </span>
                  </div>
                  <div>
                    <button className="ct-text-size-14-bold register-now">
                      Get feedback
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <HearFromUs />
    </div>
  );
};

export default OfferingsPage;
