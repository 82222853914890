import React from "react";
import WorkshopFooter from "./wsFooter";
import WorkshopListCard from "./workshopListCard";
import NavbarEmptyComponent from "../events/navbarEmpty";
import SEO from "../common/seo";

const WorkshopMainList = () => {
  return (
    <div>
      <SEO
        title="🎤 Casttree Singing Workshops | Learn from Top Singing Experts and Playback Singers"
        description="Join Casttree’s exclusive online singing workshops led by top playback singers. Learn vocal techniques, improvisation, and performance skills in live, interactive sessions. Perfect for aspiring singers worldwide! 🎶✨"
      />
      <div
        className="ct-bg-light"
        style={{ height: "100%", backgroundColor: "white" }}
      >
        <NavbarEmptyComponent />
        <WorkshopListCard />
        <WorkshopFooter showSocialMediaLinks={true} />
      </div>
    </div>
  );
};

export default WorkshopMainList;
