import React from "react";
import { useParams } from "react-router-dom";
import star from "../../assets/Star.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const WorkshopReview = ({ workshopDetail }) => {
  const { workShopId } = useParams();

  return (
    <div className="text-white py-0 px-2 ">
      <div
        className="d-flex col mt-4"
        style={{ alignItems: "center", justifyContent: "center", gap: 10 }}
      >
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
        <p
          className="ct-text-size-24 text-white mb-0"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Hear from our users
        </p>
        <img src={star} style={{ width: 24, height: 24 }} alt="star" />
      </div>

      <Swiper
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 1,
          },
        }}
        className="mySwiper mt-4 "
      >
        {workshopDetail?.itemId?.additionalDetail?.testimonials?.map(
          (item, index) => (
            <SwiperSlide key={index}>
              <div
                className="card p-3 m-lg-3 m-sm-0 text-white"
                style={{ backgroundColor: "#1C1C1E" }}
              >
                <p
                  className="ct-text-size-25 mb-0"
                  style={{ color: "#6E6E72" }}
                >
                  “
                </p>
                <p style={{ color: "#919191" }} className="ct-banner-Text-14 ">
                  “ {item.body} ”
                </p>
                <div className="d-flex justify-content-end align-items-end">
                  {/* <img
                    src={item.image}
                    alt="Profile Picture"
                    style={{ width: 80, height: 80 }}
                    className="profile-pic "
                  /> */}
                  <p className="ct-text-size-16 mt-2">- {item.name}</p>
                </div>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  );
};

export default WorkshopReview;