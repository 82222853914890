import React, { useState } from "react";

const ApplyCouponCard = ({ 
  workshopDetail, 
  couponCode, 
  handleCouponChange, 
  applyCoupon, 
  removeCoupon, 
  applySection, 
  couponStatus, 
  discountedAmount, 
  premiumDiscount, 
  getTotalPaymentAmount, 
  isButtonDisabled, 
  createServiceRequestAndPay 
}) => {

  return (
    <div className="ws-card-second ct-bg-white">
      <div className="ct-web-position-relative">
        <div className="">
          <div className="pb-2">
            <p className="ct-cirka-size-26 mb-1">
              Tell us details about you{" "}
            </p>
            <p className="ct-text-size-14-gray">
              Complete your purchase by providing your payment details.
            </p>
          </div>
          <div>
            <div className="mt-3 ct-coupon-border">
              <div className="ct-display-flex">
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  className="ct-input-card"
                  value={couponCode.toUpperCase()}
                  autoCapitalize="characters"
                  onChange={handleCouponChange}
                  contentEditable={applySection}
                />
                {applySection && (
                  <span
                    className="gradient-text ct-float-right ct-pointer ct-ws-apply-coupon"
                    onClick={applyCoupon}
                  >
                    Apply
                  </span>
                )}
                {!applySection && (
                  <span
                    className="ct-float-right ct-text-size-16 ct-pointer ct-ws-remove-coupon text-danger"
                    onClick={removeCoupon}
                  >
                    Remove
                  </span>
                )}
              </div>
              
              <div
                className="pt-2 text-left"
                style={{ textAlign: "left" }}
              >
                {couponStatus && (
                  <p className="ct-text-size-14 text-danger">
                    {couponStatus}
                  </p>
                )}
              </div>
   
              <div className="ct-web-width-90 mt-4 ">
              {premiumDiscount && (
                  <div className="ct-display-flex ct-justify-space-between mb-2">
                    <h5 className="ct-text-size-16 ct-text-success mb-2">
                      Premium Discount
                    </h5>
                    <h5 className="ct-text-size-16 ct-text-success">
                      {premiumDiscount}%
                    </h5>
                  </div>
                )}
                <div className="ct-display-flex ct-justify-space-between mb-2">
                  <h5 className="ct-text-size-16 text-white mb-2">
                    Total
                  </h5>
                  <h5 className="ct-text-size-16 text-white">
                    {workshopDetail?.itemId?.currency?.currency_code}{" "}
                    {workshopDetail?.itemId?.price}
                  </h5>
                </div>
                {discountedAmount && (
                  <div className="ct-display-flex ct-justify-space-between mb-2">
                    <h5 className="ct-text-size-16 ct-text-success mb-2">
                      Discount
                    </h5>
                    <h5 className="ct-text-size-16 ct-text-success">
                      {" "}
                      -{
                        workshopDetail?.itemId?.currency?.currency_code
                      }{" "}
                      {discountedAmount}
                    </h5>
                  </div>
                )}

                <div className="ct-display-flex ct-justify-space-between">
                  <h3 className="ct-text-size-25 text-white mb-2">
                    Grand Total
                  </h3>
                  <h3 className="ct-text-size-25 text-white">
                    {`${
                      workshopDetail?.itemId?.currency?.currency_code
                    } ${getTotalPaymentAmount()}`}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 ct-web-position-absolute">
          <button
            className="event-form-next-btn"
            id="rzp-button1"
            disabled={isButtonDisabled}
            style={{
              background:
                "linear-gradient(to right, #EE35FF 0%, #FF8761 100%)",
              color: "#fff",
            }}
            onClick={createServiceRequestAndPay}
          >
            Complete Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyCouponCard;