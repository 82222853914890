import { CasttreeEventType, CASTTREE_EVENT_SCHEMA_DETAIL } from "./eventConstants";
import useEvents from "./useEvents";

export function useTriggeredEvent() {
  const { publishEvents } = useEvents();

  const handleTriggeredEvents = async (
    eventName,
    additionalDetails = {}
  ) => {
    try {
      await publishEvents({
        additionalDetails,
        ...CASTTREE_EVENT_SCHEMA_DETAIL[eventName],
        name: eventName,
      });
    } catch (error) {
      console.error('Error publishing event:', error);
    }
  };

  return { handleTriggeredEvents };
}
