import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Fade } from "react-awesome-reveal";
import logo from "../../assets/casttree_logo.png";
import FooterData from "./footerData";

const Learn = () => {
  return (
    <div className="work-shop-background-image web-full-height">
      <div className="container">
        <div className="ct-p- ct-d-flex-justify-center-home">
          <Fade direction="left" cascade>
            <div className="ct-pointer">
              <img
                src={logo}
                alt="Casttree Logo"
                width={120}
                className="pl-5"
              />
            </div>
          </Fade>
          <div
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSfvV9trCTWLbXWcVnHEZl2CBOEzQiB7CXf6c7aNhYDxZgQCWg/viewform",
                "_blank"
              )
            }
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              cursor: "pointer", // Adds a pointer cursor to indicate it's clickable
            }}
          >
            <HelpIcon
              style={{
                fill: "white",
                fontSize: 25,
                position: "relative",
                bottom: 8,
              }}
            />
            <p className="ct-text-size-24" style={{ color: "#FFFFFF" }}>
              Help
            </p>
          </div>
        </div>
      </div>
      <FooterData />
    </div>
  );
};

export default Learn;
