import React from "react";
import star from "../../assets/Star.png";
import Learn from "../../assets/workshop-works.png";

const WorkshopMeetMentorsCard = ({ workshopDetail }) => {
  return (
    <div className="text-white p-lg-5 p-2">
      <div
        className="d-flex col mt-1"
        style={{ alignItems: "center", justifyContent: "center", gap: 10 }}
      >
        <img className="star-icon-position" src={star} style={{ width: 24, height: 24 }} alt="star" />
        <p
          className="ct-text-size-24 text-white mb-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          What is in the workshop
        </p>
        <img src={star} className="star-icon-position" style={{ width: 24, height: 24 }} alt="star" />
      </div>
      <div className="row mt-2 ">
        <div className="col-md-6 Ct-workShop-Learn mt-4 Ct-Video-title d-sm-block d-none">
          <div className="video-Description">
            <p className="ct-category-sub-text mb-3">
              {
                workshopDetail?.itemId?.additionalDetail?.videoDescription?.[0]
                  ?.title
              }
            </p>
            <p className="ct-banner-Text">
              {
                workshopDetail?.itemId?.additionalDetail?.videoDescription?.[0]
                  ?.body
              }
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center">
          <div
            className="video-thumbnail-wrapper"
            style={{ display: "flex", justifyContent: "center", Width: 700 }}
          >
            {workshopDetail?.itemId?.additionalDetail?.videoDescription?.[0]
              ?.mediaUrl ? (
              <video
                className="ct-Workshop-Learn-Banner"
                src={
                  workshopDetail?.itemId?.additionalDetail?.videoDescription?.[0]
                    ?.mediaUrl
                }
                alt="Workshop"
                style={{ Width: 700 }}
                controls
                poster={
                  workshopDetail?.itemId?.additionalDetail?.videoDescription?.[0]
                    ?.thumbnailMediaUrl
                }
              />
            ) : (
              <img
                src={
                  workshopDetail?.itemId?.additionalDetail?.videoDescription?.[0]
                    ?.thumbnailMediaUrl
                }
                alt="Thumbnail"
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopMeetMentorsCard;