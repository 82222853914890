// eventConstants.js
export const CasttreeEventType = {
  NEW_USER_CREATED_SUCCESS: "NEW_USER_CREATED_SUCCESS",
};

export const CASTTREE_EVENT_SCHEMA_DETAIL = {
  [CasttreeEventType.NEW_USER_CREATED_SUCCESS]: {
    description: "New user created successfully",
    customEventSchemaId: CasttreeEventType.NEW_USER_CREATED_SUCCESS,
    customEventSchemaVersion: 1,
    eventType: "NewUserCreatedSuccess",
  },
};
