import React from "react";
import logoImage from "../../assets/casttree_logo.png";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import Fade from "react-awesome-reveal";

const TermsConditions = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        className="header-nav navbar-light event-header container"
        fixed="top"
        collapseOnSelect
        style={{
          backgroundColor: "#000",
          color: "#FFF",
          padding: 10,
          position: "relative",
        }}
        expand="lg"
      >
        <Fade direction="left" cascade>
          <Navbar.Brand
            className="brand-name ml-lg-5 ct-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logoImage}
              alt="Casttree Logo"
              width={120}
              className="pl-5 pt-5"
            />
          </Navbar.Brand>
        </Fade>
      </Navbar>
      <div className="landing-page-main">
        <div className="ct-p-5 ct-d-flex-justify-center-home">
          <div className="">
            <div className="" style={{ textAlign: "center", marginBottom: 50 }}>
              <h1 className="ct-cirka-size-50" style={{ color: "#FFF" }}>
                Terms & Conditions
              </h1>
            </div>
            <div className="">
              <p className="ct-text-18 ct-text-light-gray pb-3">
                THIS DOCUMENT IS AN ELECTRONIC RECORD IN THE FORM OF AN
                ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGY
                (Amendment) ACT, 2023 AND RULES MADE THEREUNDER AND THE AMENDED
                PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN
                VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT,
                2000. THIS AGREEMENT DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC
                OR DIGITAL SIGNATURE.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                Please read the Terms of Use carefully. By clicking the “I
                Agree” button while using the website or accessing and using the
                Website or downloading/installing and/or accessing/making use of
                the Application or the Services and content of{" "}
                <a href="https://casttree.com/" style={{ color: "#ee36ff" }}>
                  {" "}
                  Casttree{" "}
                </a>{" "}
                you acknowledge that you have read these Terms and agree to be
                bound by the Terms as well as additional terms, including but
                not limited to the Privacy Policy as well as your rights,
                obligations and restrictions regarding your use of the{" "}
                <b>BILLION FACES TECHNOLOGIES PRIVATE LIMITED Services</b>.
              </p>
              <p className="ct-text-18 ct-text-light-gray pb-3">
                These terms and conditions (“Agreement”) sets forth the general
                terms and conditions of your use of the{" "}
                <b>BILLION FACES TECHNOLOGIES PRIVATE LIMITED</b> mobile
                application (“Mobile Application” or “Service”), Website
                <a
                  href="https://casttree.com/"
                  target="_blank"
                  style={{ color: "#ee36ff" }}
                >
                  {" "}
                  Casttree{" "}
                </a>{" "}
                and any of its related products and services (collectively,
                “Services”). This Agreement is legally binding between you
                (“User”, “you” or “your”) and ' ' (“ Billion faces technologies
                private limited ”, "Casttree", “we”, “us” or “our”) having its
                registered address at No.67, Govinda Chetty, Street,
                Kaveripattinam, Kaveripattinam Bazaar, Krishnagiri, Tamil Nadu -
                635112. By accessing and using the Mobile Application and
                Services, you acknowledge that you have read, understood, and
                agree to be bound by the terms of this Agreement. If you are
                entering into this Agreement on behalf of a business or other
                legal entity, you represent that you have the authority to bind
                such entity to this Agreement, in which case the terms “User”,
                “you” or “your” shall refer to such entity. If you do not have
                such authority, or if you do not agree with the terms of this
                Agreement, you must not accept this Agreement and may not access
                and use the Mobile Application and Services. You acknowledge
                that this Agreement is a contract between you and{" "}
                <b>'BILLION FACES TECHNOLOGIES PRIVATE LIMITED'</b> even though
                it is electronic and is not physically signed by you, and it
                governs your use of the Mobile Application and Services.
              </p>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Accounts and membership
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  You must be at least 13 years of age to use the Mobile
                  Application and Services. By using the Mobile Application and
                  Services and by agreeing to this Agreement you warrant and
                  represent that you are at least 13 years of age. If you create
                  an account in the Mobile Application, you are responsible for
                  maintaining the security of your account and you are fully
                  responsible for all activities that occur under the account
                  and any other actions taken in connection with it. We may, but
                  have no obligation to, monitor and review new accounts before
                  you may sign in and start using the Services. Providing false
                  contact information of any kind may result in the termination
                  of your account. You must immediately notify us of any
                  unauthorized uses of your account or any other breaches of
                  security. We will not be liable for any acts or omissions by
                  you, including any damages of any kind incurred as a result of
                  such acts or omissions. We may suspend, disable, or delete
                  your account (or any part thereof) if we determine that you
                  have violated any provision of this Agreement or that your
                  conduct or content would tend to damage our reputation and
                  goodwill. If we delete your account for the foregoing reasons,
                  you may not re-register for our Services. We may block your
                  email address and Internet protocol address to prevent further
                  registration.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  User content
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  We do not own any data, information or material (collectively,
                  “Content”) that you submit in the Mobile Application in the
                  course of using the Service. You shall have sole
                  responsibility for the accuracy, quality, integrity, legality,
                  reliability, appropriateness, and intellectual property
                  ownership or right to use of all submitted Content. We may
                  monitor and review the Content in the Mobile Application
                  submitted or created using our Services by you. You grant us
                  permission to access, copy, distribute, store, transmit,
                  reformat, display and perform the Content of your user account
                  solely as required for the purpose of providing the Services
                  to you. Without limiting any of those representations or
                  warranties, we have the right, though not the obligation, to,
                  in our own sole discretion, refuse or remove any Content that,
                  in our reasonable opinion, violates any of our policies or is
                  in any way harmful or objectionable. Unless specifically
                  permitted by you, your use of the Mobile Application and
                  Services does not grant us the license to use, reproduce,
                  adapt, modify, publish or distribute the Content created by
                  you or stored in your user account for commercial, marketing
                  or any similar purpose.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Accuracy of information
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  Occasionally there may be information in the Mobile
                  Application that contains typographical errors, inaccuracies
                  or omissions that may relate to promotions and offers. We
                  reserve the right to correct any errors, inaccuracies or
                  omissions, and to change or update information or cancel
                  orders if any information in the Mobile Application or
                  Services is inaccurate at any time without prior notice
                  (including after you have submitted your order). We undertake
                  no obligation to update, amend or clarify information in the
                  Mobile Application including, without limitation, pricing
                  information, except as required by law. No specified update or
                  refresh date applied in the Mobile Application should be taken
                  to indicate that all information in the Mobile Application or
                  Services has been modified or updated.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Uptime guarantee
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  We offer a Service uptime guarantee of 99% of available time
                  per month. The service uptime guarantee does not apply to
                  service interruptions caused by: (1) periodic scheduled
                  maintenance or repairs we may undertake from time to time; (2)
                  interruptions caused by you or your activities; (3) outages
                  that do not affect core Service functionality; (4) causes
                  beyond our control or that are not reasonably foreseeable; and
                  (5) outages related to the reliability of certain programming
                  environments.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Backups
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  We perform regular backups of the Content and will do our best
                  to ensure completeness and accuracy of these backups. In the
                  event of the hardware failure or data loss we will restore
                  backups automatically to minimize the impact and downtime.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Links to other resources
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  Although the Mobile Application and Services may link to other
                  resources (such as websites, mobile applications, etc.), we
                  are not, directly or indirectly, implying any approval,
                  association, sponsorship, endorsement, or affiliation with any
                  linked resource, unless specifically stated herein. We are not
                  responsible for examining or evaluating, and we do not warrant
                  the offerings of, any businesses or individuals or the content
                  of their resources. We do not assume any responsibility or
                  liability for the actions, products, services, and content of
                  any other third parties. You should carefully review the legal
                  statements and other conditions of use of any resource which
                  you access through a link in the Mobile Application and
                  Services. Your linking to any other off-site resources is at
                  your own risk.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Prohibited uses
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  In addition to other terms as set forth in the Agreement, you
                  are prohibited from using the Mobile Application and Services
                  or Content: (a) for any unlawful purpose; (b) to solicit
                  others to perform or participate in any unlawful acts; (c) to
                  violate any international, federal, provincial or state
                  regulations, rules, laws, or local ordinances; (d) to infringe
                  upon or violate our intellectual property rights or the
                  intellectual property rights of others; (e) to harass, abuse,
                  insult, harm, defame, slander, disparage, intimidate, or
                  discriminate based on gender, sexual orientation, religion,
                  ethnicity, race, age, national origin, or disability; (f) to
                  submit false or misleading information; (g) to upload or
                  transmit viruses or any other type of malicious code that will
                  or may be used in any way that will affect the functionality
                  or operation of the Mobile Application and Services, third
                  party products and services, or the Internet; (h) to spam,
                  phish, pharm, pretext, spider, crawl, or scrape; (i) for any
                  obscene or immoral purpose; or (j) to interfere with or
                  circumvent the security features of the Mobile Application and
                  Services, third party products and services, or the Internet.
                  We reserve the right to terminate your use of the Mobile
                  Application and Services for violating any of the prohibited
                  uses.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Intellectual property rights
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  “Intellectual Property Rights” means all present and future
                  rights conferred by statute, common law or equity in or in
                  relation to any copyright and related rights, trademarks,
                  designs, patents, inventions, goodwill and the right to sue
                  for passing off, rights to inventions, rights to use, and all
                  other intellectual property rights, in each case whether
                  registered or unregistered and including all applications and
                  rights to apply for and be granted, rights to claim priority
                  from, such rights and all similar or equivalent rights or
                  forms of protection and any other results of intellectual
                  activity which subsist or will subsist now or in the future in
                  any part of the world. This Agreement does not transfer to you
                  any intellectual property owned by 'BILLION FACES TECHNOLOGIES
                  PRIVATE LIMITED ' or third parties, and all rights, titles,
                  and interests in and to such property will remain (as between
                  the parties) solely with 'BILLION FACES TECHNOLOGIES PRIVATE
                  LIMITED '. All trademarks, service marks, graphics and logos
                  used in connection with the Mobile Application and Services,
                  are trademarks or registered trademarks of 'BILLION FACES
                  TECHNOLOGIES PRIVATE LIMITED ' or its licensors. Other
                  trademarks, service marks, graphics and logos used in
                  connection with the Mobile Application and Services may be the
                  trademarks of other third parties. Your use of the Mobile
                  Application and Services grants you no right or license to
                  reproduce or otherwise use any of 'BILLION FACES TECHNOLOGIES
                  PRIVATE LIMITED ' or third party trademarks.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Disclaimer of warranty
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  You agree that such Service is provided on an “as is” and “as
                  available” basis and that your use of the Mobile Application
                  and Services is solely at your own risk. We expressly disclaim
                  all warranties of any kind, whether express or implied,
                  including but not limited to the implied warranties of
                  merchantability, fitness for a particular purpose and
                  non-infringement. We make no warranty that the Services will
                  meet your requirements, or that the Service will be
                  uninterrupted, timely, secure, or error-free; nor do we make
                  any warranty as to the results that may be obtained from the
                  use of the Service or as to the accuracy or reliability of any
                  information obtained through the Service or that defects in
                  the Service will be corrected. You understand and agree that
                  any material and/or data downloaded or otherwise obtained
                  through the use of Service is done at your own discretion and
                  risk and that you will be solely responsible for any damage or
                  loss of data that results from the download of such material
                  and/or data. We make no warranty regarding any goods or
                  services purchased or obtained through the Service or any
                  transactions entered into through the Service unless stated
                  otherwise. No advice or information, whether oral or written,
                  obtained by you from us or through the Service shall create
                  any warranty not expressly made herein.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Limitation of liability
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  To the fullest extent permitted by applicable law, in no event
                  will 'CASTREE' and its affiliates, directors, officers,
                  employees, agents, suppliers or licensors be liable to any
                  person for any indirect, incidental, special, punitive, cover
                  or consequential damages (including, without limitation,
                  damages for lost profits, revenue, sales, goodwill, use of
                  content, impact on business, business interruption, loss of
                  anticipated savings, loss of business opportunity) however
                  caused, under any theory of liability, including, without
                  limitation, contract, tort, warranty, breach of statutory
                  duty, negligence or otherwise, even if the liable party has
                  been advised as to the possibility of such damages or could
                  have foreseen such damages. To the maximum extent permitted by
                  applicable law, the aggregate liability of 'BILLION FACES
                  TECHNOLOGIES PRIVATE LIMITED ' and its affiliates, officers,
                  employees, agents, suppliers and licensors relating to the
                  services will be limited to an amount greater of one dollar or
                  any amounts actually paid in cash by you to BILLION FACES
                  TECHNOLOGIES PRIVATE LIMITED for the prior one month period
                  prior to the first event or occurrence giving rise to such
                  liability. The limitations and exclusions also apply if this
                  remedy does not fully compensate you for any losses or fails
                  of its essential purpose.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Indemnification
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  You agree to indemnify and hold BILLION FACES TECHNOLOGIES
                  PRIVATE LIMITED and its affiliates, directors, officers,
                  employees, agents, suppliers and licensors harmless from and
                  against any liabilities, losses, damages or costs, including
                  reasonable attorneys’ fees, incurred in connection with or
                  arising from any third party allegations, claims, actions,
                  disputes, or demands asserted against any of them as a result
                  of or relating to your Content, your use of the Mobile
                  Application and Services or any willful misconduct on your
                  part.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Severability
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  All rights and restrictions contained in this Agreement may be
                  exercised and shall be applicable and binding only to the
                  extent that they do not violate any applicable laws and are
                  intended to be limited to the extent necessary so that they
                  will not render this Agreement illegal, invalid or
                  unenforceable. If any provision or portion of any provision of
                  this Agreement shall be held to be illegal, invalid or
                  unenforceable by a court of competent jurisdiction, it is the
                  intention of the parties that the remaining provisions or
                  portions thereof shall constitute their agreement with respect
                  to the subject matter hereof, and all such remaining
                  provisions or portions thereof shall remain in full force and
                  effect.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Dispute resolution
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  The formation, interpretation, and performance of this
                  Agreement and any disputes arising out of it shall be governed
                  by the substantive and procedural laws of Tamil Nadu, India
                  without regard to its rules on conflicts or choice of law and,
                  to the extent applicable, the laws of India. The exclusive
                  jurisdiction and venue for actions related to the subject
                  matter hereof shall be the courts located in Tamil Nadu,
                  India, and you hereby submit to the personal jurisdiction of
                  such courts. You hereby waive any right to a jury trial in any
                  proceeding arising out of or related to this Agreement. The
                  United Nations Convention on Contracts for the International
                  Sale of Goods does not apply to this Agreement.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Assignment
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  You may not assign, resell, sub-license or otherwise transfer
                  or delegate any of your rights or obligations hereunder, in
                  whole or in part, without our prior written consent, which
                  consent shall be at our own sole discretion and without
                  obligation; any such assignment or transfer shall be null and
                  void. We are free to assign any of its rights or obligations
                  hereunder, in whole or in part, to any third party as part of
                  the sale of all or substantially all of its assets or stock or
                  as part of a merger.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Changes and amendments
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  We reserve the right to modify this Agreement or its terms
                  relating to the Mobile Application and Services at any time,
                  effective upon posting of an updated version of this Agreement
                  in the Mobile Application. When we do, we will send you an
                  email to notify you. Continued use of the Mobile Application
                  and Services after any such changes shall constitute your
                  consent to such changes.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Acceptance of these terms
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  You acknowledge that you have read this Agreement and agree to
                  all its terms and conditions. By accessing and using the
                  Mobile Application and Services you agree to be bound by this
                  Agreement. If you do not agree to abide by the terms of this
                  Agreement, you are not authorized to access or use the Mobile
                  Application and Services.
                </p>
              </div>
              <div className="">
                <p className="ct-text-18 ct-text-light pb-3 ct-text-weight-600">
                  Contacting us
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  If you would like to contact us to understand more about this
                  Agreement or wish to contact us concerning any matter relating
                  to it, you may send an email to{" "}
                  <a
                    href="mailto:contact@casttree.com"
                    style={{ color: "#ee36ff" }}
                  >
                    contact@casttree.com
                  </a>
                  .{" "}
                  <span className="pl-1">
                    Our registered address is No.67, Govinda Chetty, Street,
                    Kaveripattinam, Kaveripattinam Bazaar, Krishnagiri, Tamil
                    Nadu - 635112.
                  </span>
                </p>
                <p className="ct-text-18 ct-text-light-gray pb-3">
                  This document was last updated on 20th May 2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
