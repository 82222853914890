import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOffOutlined";
import OtpInput from "react-otp-input";
import useResendTimer from "../common/useResendTimer";
import AuthService from "../../services/authService";
import toast from "react-hot-toast";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const LoginScreen = ({ onLoginSuccess }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isGeneratingOTP, setIsGeneratingOTP] = useState(false);
  const [isValidPhnNumber, setIsValidPhnNumber] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const [canResendOTP, setCanResendOTP] = useState(false);
  const [showOTPDialog, setShowOTPDialog] = useState(false);
  const [disableVerifyOTPBtn, setDisableVerifyOTPBtn] = useState(false);
  const { timer, resetTimer } = useResendTimer(30);
  const [otp, setOtp] = useState("");
  const authService = AuthService;

  const handleInputChange = (event) => {
    let value = event.target.value.toString();
    value = value.replace(/\D/g, "");
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setPhoneNumber(value);
    setIsValidPhnNumber(value.length === 10 && /^[6-9]\d{9}$/.test(value));
  };

  const handleGenerateOTP = async () => {
    try {
      setIsGeneratingOTP(true);
      const response = await authService.generateOTP(phoneNumber.toString());
      setIsGeneratingOTP(false);
      setShowOTPDialog(true);
      startCountdown();
      toast.success("OTP generated successfully");
    } catch (error) {
      toast.error("Enter valid phone number");
      setIsGeneratingOTP(false);
    }
  };

  const loginOTPVerify = async () => {
    try {
      const otpResponse = await authService.otpLogin(phoneNumber, otp.toString());
      setDisableVerifyOTPBtn(true);
      onLoginSuccess(otpResponse);
    } catch (error) {
      toast.error("Invalid OTP");
      setDisableVerifyOTPBtn(false);
    }
  };

  const startCountdown = () => {
    setCanResendOTP(false);
    const countDown = () => {
      setResendTime((prevTime) => {
        if (prevTime === 0) {
          setCanResendOTP(true);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
      if (resendTime > 0) {
        setTimeout(countDown, 1000);
      }
    };
    countDown();
  };

  const handleClose = () => {
    setShowOTPDialog(false);
    setPhoneNumber("");
    startCountdown();
    resetTimer();
    setResendTime(0);
    window.location.reload();
  };

  const handleResendOTP = () => {
    if (timer === 0) {
      startCountdown();
      resetTimer();
      setShowOTPDialog(true);
      handleGenerateOTP();
      setResendTime(30);
      setOtp("");
    }
  };

  const handleOTPInputChange = (value) => {
    setOtp(value);
    if (value.length === 4) {
      setDisableVerifyOTPBtn(true);
    } else {
      setDisableVerifyOTPBtn(false);
    }
  };

  return (
    <div>
      <div className="mt-3">
        <p className="copy-right-text mb-2" style={{ fontWeight: 600, color: "#000" }}>
          Mobile Number
        </p>
        <div className="ct-d-flex-mbl">
          <input
            type="number"
            placeholder="enter your mobile number"
            className="phone-no-input"
            value={phoneNumber}
            onChange={handleInputChange}
          />
          <button
            onClick={handleGenerateOTP}
            disabled={!isValidPhnNumber || isGeneratingOTP}
            className="verify-btn"
            style={{
              background: isValidPhnNumber
                ? "linear-gradient(to right, #EE35FF 0%, #FF8761 100%)"
                : "gray",
            }}
          >
            Verify
          </button>
        </div>
      </div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={showOTPDialog}>
        <p className="ct-text-18 ct-text-weight-600 ct-text-dark p-3 pt-3" id="customized-dialog-title">
          Enter OTP
        </p>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
          className="ct-pointer"
        >
          <HighlightOffIcon className="ct-text-18 ct-text-dark" />
        </IconButton>
        <DialogContent dividers>
          <div className="dialog-custom-input">
            <OtpInput
              value={otp}
              shouldAutoFocus
              onChange={handleOTPInputChange}
              numInputs={4}
              isInputNum={true}
              type="number"
              inputStyle={{
                width: "42px",
                borderColor: "#B9B9B9",
                height: "46px",
                marginRight: "20px",
                borderWidth: 1,
                backgroundColor: "#F0F0F0",
                borderRadius: 5,
                fontSize: 20,
              }}
              className="customOtpContainer"
              renderSeparator={<span></span>}
              renderInput={(props) => (
                <input
                  {...props}
                  inputMode="numeric"
                  type="number"
                  pattern="[0-9]*"
                />
              )}
            />
          </div>
          <div className="mt-4 ct-text-center">
            {timer > 0 ? (
              <p
                className="ct-text-14 mb-2"
                style={{
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                You can resend code in{" "}
                <span style={{ color: "#EE35FF" }}>
                  {" "}
                  {resendTime < 10 ? `0${resendTime}` : resendTime} seconds{" "}
                </span>
              </p>
            ) : (
              canResendOTP && (
                <p
                  className="ct-text-14 mb-2"
                  style={{
                    fontWeight: 600,
                    color: "#000",
                  }}
                >
                  Didn't receive the OTP?{" "}
                  <span
                    onClick={handleResendOTP}
                    className="ct-pointer"
                    style={{ color: "#EE35FF" }}
                  >
                    Resend OTP
                  </span>
                </p>
              )
            )}
          </div>
          <div className="mt-4 ct-text-center dialog-custom-input">
            <p className="ct-text-14 mb-2" style={{ fontWeight: 600, color: "#000" }}>
              By clicking on verify, you accept to the{" "}
              <span
                className="ct-pointer"
                style={{
                  textDecoration: "underline",
                  color: "#EE35FF",
                }}
              >
                <a
                  href="/termsConditions"
                  target="_blank"
                  style={{ color: "#ee35ff" }}
                >
                  terms and conditions
                </a>
              </span>{" "}
              of Casttree events and awards
            </p>
          </div>
          <div className="mt-4 ct-text-center">
            <button
              style={{
                background: otp.length
                  ? "linear-gradient(to right, rgb(238, 53, 255) 0%, rgb(255, 135, 97) 100%)"
                  : "#C2C2C2",
                color: otp.length ? "#FFF" : "#FFF",
              }}
              onClick={loginOTPVerify}
              disabled={!disableVerifyOTPBtn}
              className="next-btn-small mbl-my-4"
            >
              Verify
            </button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default LoginScreen;